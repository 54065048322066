import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  CardTitle,
  Table,
  Row,
} from "reactstrap";
import {fetchDetails} from "helpers/backend_helper"
//redux
import { useSelector, useDispatch } from "react-redux";

const GameTeamStats = props => {
  const dispatch = useDispatch();

  const [allGroups, setAllGroups] = useState([]);
  const [groupsCountMap, setGroupsCountMap] = useState(new Map());
  const [total, setTotal] = useState(0);
  const [allResponse, setAllResponse] = useState([]);

  useEffect(()=>{
    getEventGames()
  },[props.gameStatsTriggerCounter])

  const getEventGames=async()=>{
    let inputToSend = {
      "data": {
        "type": 'GAME_COUNTS',
        "game": {
          "gameId": props.gameId,
          "eventCode": props.eventCode,
        }
      }
    }
    const resp = await fetchDetails(inputToSend)
    if(resp && resp.status=='success'){
      //setAllEventGames(resp.data)
      if(resp.data){
        setAllResponse([...resp.data])
        processResponse(resp.data)
      }
    }
  }

  const processResponse=(data)=>{
    const allGroups = []
    const groupCountMap = new Map()
    let total = 0
    if(data && data.length > 0){
      data.forEach(each => {
        if(!allGroups.includes(each['groupName'])){
          allGroups.push(each['groupName'])
        }
        if(each.gameId && props.gameId && props.gameId == each.gameId){
          groupCountMap.set(each.groupName,each.total)
          total = total + Number(each.total)
        }
      })
    }
    setTotal(total)
    setAllGroups(allGroups)
    setGroupsCountMap(groupCountMap)
  }

  useEffect(()=>{
    processResponse(allResponse)
  },[props.gameId])

  return (
    <React.Fragment>
      <Row>
        <Col md="12">
          <Card>
            <CardBody>
              <CardTitle className="mb-3">Number of Teams</CardTitle>
                <div className="table-responsive">
                <Table className="table mb-0 table-sm">
                  <tbody>
                    {allGroups.map((each,i) => (
                      <tr key={"teams"+i}>
                        <td>{each}</td>
                        <td>{groupsCountMap.get(each) ? groupsCountMap.get(each) : '0'}</td>
                    </tr>
                    ))}
                    <tr>
                      <th>Total :</th>
                      <th>{total}</th>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

GameTeamStats.propTypes = {
};

export default GameTeamStats;
