import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { differenceBy } from "lodash";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap";
import {fetchDetails, insertDetails, updateDetails} from "helpers/backend_helper"
//redux
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import * as Yup from "yup";
import { useFormik } from "formik";
import CustomCreatableSelect from "components/Common/CustomCreatableSelect"
import { changePreloader } from "../../store/actions";
import { showSuccessAlert, showFailureAlert, findChangesV2 } from "helpers/function_helper"
import "flatpickr/dist/themes/airbnb.css"
import Flatpickr from "react-flatpickr"
import * as dayjs from 'dayjs'

const NewMatch = props => {
  const dispatch = useDispatch();
  //const { match : { params } } = props

  const gameStatusDD = [{label:'YTS',value:'YTS'},{label:'In Progress',value:'In Progress'},{label:'Completed',value:'Completed'},{label:'Cancelled',value:'Cancelled'}]

  const [inProgress, setInProgress] = useState(false);
  const [resetValue, setResetValue] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedRound, setSelectedRound] = useState();
  const [selectedStatus, setSelectedStatus] = useState(gameStatusDD[0]);
  const [registeredTeamList, setRegisteredTeamList] = useState([]);
  const [registeredFilteredTeamList, setRegisteredFilteredTeamList] = useState([]);
  const [selectedTeams, setSelectedTeams] = useState();
  const [excludeAlreadyAddedTeams, setExcludeAlreadyAddedTeams] = useState(true);
  const [referenceTeamList, setReferenceTeamList] = useState([]);
  const [prevMatchList, setPrevMatchList] = useState([]);

  const [allRounds, setAllRounds] = useState([]);

  useEffect(()=>{
    getAllRounds()
    getRegisteredTeams()
  },[])

  useEffect(()=>{
    //console.log('props.matchDetailsForEdit:',props.matchDetailsForEdit)
    if(props.isEditMatch){
      setIsEdit(true)
      validation.setValues({
        matchId: props.matchDetailsForEdit.matchId,
        gameId: props.gameId,
        round: props.matchDetailsForEdit.roundId,
        gameStatus: props.matchDetailsForEdit.gameStatus,
        venue: props.matchDetailsForEdit.venue,
        dateAndTime: dayjs(props.matchDetailsForEdit.matchTimeGMT).utc(true).local(),
        comments: props.matchDetailsForEdit.comments,
        teams:[]
      })
      setSelectedStatus(gameStatusDD.filter(each=>each.value == props.matchDetailsForEdit.gameStatus)[0])
    }
  },[props.isEditMatch])

  useEffect(()=>{
    if(props.isEditMatch && props.matchDetailsForEdit.roundId){
      const round  = allRounds.filter(each => each.value == props.matchDetailsForEdit.roundId)
      if(round && round.length == 1){
        setSelectedRound(round)
      }
    }
  },[allRounds])

  useEffect(()=>{
    if(props.isEditMatch && props.matchDetailsForEdit.teams){
      const teamsArray = []
      props.matchDetailsForEdit.teams.forEach(each=>{
        const foundList = registeredTeamList.filter(eachTeam=> (eachTeam.teamId == each.teamId && eachTeam.team_name == each.teamName))
        //console.log('foundList:',foundList)
        if(foundList && foundList.length ==1){
          teamsArray.push(foundList[0])
        }
        else{
          //console.log('prevMatchList:',prevMatchList)
          const foundListPrev = prevMatchList.filter(eachTeam=>eachTeam.teamName == each.teamName)
          //console.log('foundListPrev:',foundListPrev)
          if(foundListPrev && foundListPrev.length ==1){
            teamsArray.push(foundListPrev[0])
          }
        }
      })
      setSelectedTeams(teamsArray)
      setReferenceTeamList([...teamsArray])
      validation.setFieldValue('teams',teamsArray)
    }
  },[registeredTeamList,prevMatchList])

  const getAllRounds=async()=>{
    let inputToSend = {
      "data": {
        "type": 'ALL_ROUNDS',
        "game": {
          "gameId": "",
        }
      }
    }
    const resp = await fetchDetails(inputToSend)
    if(resp && resp.status=='success'){
      if(resp.data){
        setAllRounds(resp.data)
      }
    }
  }

  const getRegisteredTeams=async()=>{
    let inputToSend = {
      "data": {
        "type": 'GAME_TEAMS',
        "game": {
          "gameCatId": props.gameCatId,
          "eventCode": props.eventCode,
        }
      }
    }
    const resp = await fetchDetails(inputToSend)
    if(resp && resp.status=='success'){
      if(resp.data){
        prepareTeamDD(resp.data)
        //setAllRounds(resp.data)
      }
    }
  }

  const prepareTeamDD=(teamList)=>{
    const teamDropDown = []
    teamList.forEach(each=>{
      teamDropDown.push({
        ...each,
        teamId: each.id,
        value: each.id,
        label : each.team_name + '[' + each.group_name + ']'
      })
    })
    setRegisteredTeamList(teamDropDown)
    if(props.existingMatches){
      //console.log('props.existingMatches::',props.existingMatches)
      const prevMatches = preparePreviousMatchesDropDown(props.existingMatches)
      setPrevMatchList(prevMatches)
      //console.log('prevMatches::',prevMatches)
      setRegisteredFilteredTeamList([{
        label: 'Teams',
        options: teamDropDown
      },
      {
        label: 'Previous Matches',
        options: prevMatches
      }])
    }
    else{
      setRegisteredFilteredTeamList([{
        label: 'Teams',
        options: teamDropDown
      }])
    }
  }

  const preparePreviousMatchesDropDown=(matches)=>{
    const previousMatchesDD = []
    matches.forEach(match => {
      if(match.roundId != selectedRound?.value){
        let teamNames = ''
        match.teams.forEach((team,idx) => {
          if(idx > 0){
            teamNames = teamNames + ' vs ' + team.teamName + ' [' +team.groupName+']'
          }
          else{
            teamNames = teamNames + team.teamName + ' [' +team.groupName+']'
          }
        })
        previousMatchesDD.push({
          label : match.matchCode + ' - Winner ('+teamNames+')',
          type: 1, //for winner
          value : match.matchId,
          teamName : match.matchCode + ' - Winner'
        })
        previousMatchesDD.push({
          label : match.matchCode + ' - Loser ('+teamNames+')',
          type: 0, //for loser
          value : match.matchId,
          teamName : match.matchCode + ' - Loser'
        })
      }
    })
    return previousMatchesDD
  }

  const handleRoundChange=(round)=>{
    setSelectedRound(round)
    validation.setFieldValue('round',round.value)
  }
  const handleStatusChange=(status)=>{
    setSelectedStatus(status)
    validation.setFieldValue('gameStatus',status.value)
  }
  const handleTeamChange=(teams)=>{
    setSelectedTeams(teams)
    validation.setFieldValue('teams',teams)
  }

  
  const onSelect=(option)=>{
    //console.log('option:',option)
    validation.setFieldValue(option.objKey,option.selectedOption)
  }

  const validation = useFormik({
    enableReinitialize: false,
    initialValues: {
      matchId:'',
      gameId: props.gameId,
      round: '',
      gameStatus: gameStatusDD[0].value,
      venue: '',
      dateAndTime: '',
      comments: '',
      teams:[]
    },
    validationSchema: Yup.object({
      round: Yup.string().trim().required("Please select the round."),
      gameStatus: Yup.string().trim().required("Please select the game status."),
      dateAndTime: Yup.string().trim().required("Please select the date & time."),
      teams: Yup.array().required().min(2,"Please select 2 teams")
    }),
    onSubmit: (values) => {
      //console.log('values:',values)
      saveDetails(values)
    }
  });

  const saveDetails = async(values)=>{
    setInProgress(true)
    dispatch(changePreloader(true))

    //console.log('isEdit?',isEdit)
    if(isEdit){
      //const changes = findChangesV2(props.matchDetailsForEdit, values ,[])
      //console.log('referenceTeamList:',referenceTeamList)
      //console.log('validation.values.teams:',validation.values.teams)
      const teamUpdates1 = differenceBy(referenceTeamList, validation.values.teams, ['teamId','teamName'])
      const teamUpdates2 = differenceBy(validation.values.teams,referenceTeamList, ['teamId','teamName'])
      //console.log('teamUpdates1:',teamUpdates1)
      //console.log('teamUpdates2:',teamUpdates2)
      let newTeams = []
      if(teamUpdates1.length >  0 || teamUpdates2.length > 0){
        newTeams = validation.values.teams
      }
      let inputToSend = {
        "data": {
          "type": 'UPDATE_MATCH',
          "match":{
            ...values,
            teams: newTeams
          }
        }
      }
      const resp = await updateDetails(inputToSend)
      //console.log('resp:',resp)
      if(resp && resp.status == 'success'){
        showSuccessAlert('Updated Successfully!')
        //validation.resetForm()
        //setSelectedRound(null)
        setSelectedTeams(null)
        validation.setFieldValue('teams',[])
        //setSelectedStatus(gameStatusDD[0])
        props.onAdditionSuccess()
      }
    }
    else{
      let inputToSend = {
        "data": {
          "type": 'NEW_MATCH',
          "match":{
            ...values
          }
        }
      }
      const resp = await insertDetails(inputToSend)
      //console.log('resp:',resp)
      if(resp && resp.status == 'success'){
        showSuccessAlert('Added Successfully!')
        //validation.resetForm()
        //setSelectedRound(null)
        setSelectedTeams(null)
        validation.setFieldValue('teams',[])
        //setSelectedStatus(gameStatusDD[0])
        props.onAdditionSuccess()
      }
    }

    setInProgress(false)
    dispatch(changePreloader(false))
  }

  const closePopUp=()=>{
    props.onClosePopUp()
  }

  useEffect(()=>{
    if(props.existingMatches && excludeAlreadyAddedTeams){
      const consolidatedAddedTeams = []
      props.existingMatches.forEach(each=> {
        if(each.roundId == selectedRound?.value){
          consolidatedAddedTeams.push(...each.teams)
        }
      })
      const nonExistingTeams = differenceBy(registeredTeamList, consolidatedAddedTeams, 'teamId')
      const prevMatches = preparePreviousMatchesDropDown(props.existingMatches)
      const nonExistingPrev = differenceBy(prevMatches, consolidatedAddedTeams, 'teamName')
      setRegisteredFilteredTeamList([{
        label: 'Teams',
        options: nonExistingTeams
      },
      {
        label: 'Previous Matches',
        options: nonExistingPrev
      }])
    }
    else{
      //setRegisteredFilteredTeamList([...registeredTeamList])
      const prevMatches = preparePreviousMatchesDropDown(props.existingMatches)
      setRegisteredFilteredTeamList([{
        label: 'Teams',
        options: [...registeredTeamList]
      },
      {
        label: 'Previous Matches',
        options: prevMatches
      }])
    }
  },[excludeAlreadyAddedTeams, props.existingMatches, registeredTeamList, selectedRound])

  return (
    <React.Fragment>
      <div className="">
        <Container fluid={true}>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row className="text-center mb-2">
                    <Col>
                      <span className="page-heading">Match {props.isEditMatch? ' - ' + props.matchDetailsForEdit.matchCode:''}</span>
                    </Col>
                  </Row>
                  <Row className="mt-3 align-items-center">
                    <Col xs={12} md={12}>
                      <Row>
                        <Col xs={12} md={2} className="text-sm-start text-md-end">
                          <Label
                            htmlFor="dateNTime"
                            className="col-form-label"
                          >
                            Date & Time :
                          </Label>
                        </Col>
                        <Col xs={12} md={8} className="">
                          <Flatpickr
                            id="dateAndTime"
                            options={{
                              enableTime: true,
                              time_24hr: false,
                              //noCalendar: true,
                              //altInputClass : "invisible",
                              inline: false,
                              altInput: true,
                              dateFormat: "Y-m-d H:i",
                              defaultDate: props.isEditMatch? (dayjs(props.matchDetailsForEdit.matchTimeGMT).utc(true).local().format()): (new Date())
                            }}
                            onChange={date => {
                              validation.setFieldValue('dateAndTime',dayjs(date))
                              //setEndTime(dayjs(date))
                            }}
                          />
                          {validation && validation.errors && validation.touched.dateAndTime && validation.errors.dateAndTime && 
                            <span className="text-danger ms-1"><small>{validation.errors.dateAndTime}</small></span>
                          }
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="mt-3 align-items-center">
                    <Col xs={12} md={12}>
                      <Row>
                        <Col xs={12} md={2} className="text-sm-start text-md-end">
                          <Label
                            htmlFor="name"
                            className="col-form-label"
                          >
                            Round :
                          </Label>
                        </Col>
                        <Col xs={12} md={8} className="">
                          <Select
                            value={selectedRound}
                            onChange={s => {
                              handleRoundChange(s)
                            }}
                            isLoading={inProgress}
                            isDisabled={false}
                            isMulti={false}
                            options={allRounds}
                            placeholder="Select..."
                            classNamePrefix="select2-selection"
                            menuPortalTarget={document.body}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                          />
                          {validation && validation.errors && validation.touched.round && validation.errors.round && 
                            <span className="text-danger ms-1"><small>{validation.errors.round}</small></span>
                          }
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="mt-3 align-items-center">
                    <Col xs={12} md={12}>
                      <Row>
                        <Col xs={12} md={2} className="text-sm-start text-md-end">
                          <Label
                            htmlFor="venue"
                            className="col-form-label"
                          >
                            Venue :
                          </Label>
                        </Col>
                        <Col xs={12} md={8} className="">
                          <Input
                            maxLength={20}
                            type="text"
                            name="venue"
                            className="form-control"
                            id="venue"
                            placeholder="Venue"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.venue || ""}
                            invalid={
                              validation.touched.venue && validation.errors.venue ? true : false
                            }
                          />
                          {validation.touched.venue && validation.errors.venue ? (
                            <FormFeedback type="invalid">{validation.errors.venue}</FormFeedback>
                          ) : null}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="mt-3 align-items-center">
                    <Col xs={12} md={12}>
                      <Row>
                        <Col xs={12} md={2} className="text-sm-start text-md-end">
                          <Label
                            htmlFor="name"
                            className="col-form-label"
                          >
                            Status :
                          </Label>
                        </Col>
                        <Col xs={12} md={8} className="">
                          <Select
                            value={selectedStatus}
                            onChange={s => {
                              handleStatusChange(s)
                            }}
                            isLoading={inProgress}
                            isDisabled={false}
                            isMulti={false}
                            options={gameStatusDD}
                            placeholder="Select..."
                            classNamePrefix="select2-selection"
                            menuPortalTarget={document.body}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                          />
                          {validation && validation.errors && validation.touched.gameStatus && validation.errors.gameStatus && 
                            <span className="text-danger ms-1"><small>{validation.errors.gameStatus}</small></span>
                          }
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  
                  <Row className="mt-3 align-items-center">
                    <Col xs={12} md={12}>
                      <Row>
                        <Col xs={12} md={2} className="text-sm-start text-md-end">
                          <Label
                            htmlFor="comments"
                            className="col-form-label"
                          >
                            Comments :
                          </Label>
                        </Col>
                        <Col xs={12} md={8} className="">
                          <Input
                              name="comments"
                              type="textarea"
                              rows="2"
                              maxLength={200}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.comments || ""}
                              invalid={
                                validation.touched.comments && validation.errors.comments ? true : false
                              }
                            />
                          {validation.touched.comments && validation.errors.comments ? (
                            <FormFeedback type="invalid">{validation.errors.comments}</FormFeedback>
                          ) : null}
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Row className="mt-3 align-items-center">
                    <Col xs={12} md={12}>
                      <Row>
                        <Col xs={12} md={2} className="text-sm-start text-md-end">
                          <Label
                            htmlFor="teams"
                            className="col-form-label"
                          >
                            Pick Teams :
                          </Label>
                        </Col>
                        <Col xs={12} md={8} className="">
                          <Select
                            value={selectedTeams}
                            onChange={s => {
                              handleTeamChange(s)
                            }}
                            isLoading={inProgress}
                            isDisabled={false}
                            isMulti={true}
                            options={registeredFilteredTeamList}
                            placeholder="Select..."
                            classNamePrefix="select2-selection"
                            menuPortalTarget={document.body}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                          />
                          {validation && validation.errors && validation.touched.teams && validation.errors.teams && 
                            <span className="text-danger ms-1"><small>{validation.errors.teams}</small></span>
                          }
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="mt-1 align-items-center">
                    <Col xs={12} md={12}>
                      <Row>
                        <Col xs={12} md={2} className="text-sm-start text-md-end">
                        </Col>
                        <Col xs={12} md={8} className="">
                          <div className="form-check form-check-info mb-3">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              name={"customCheckcolor3"}
                              id="customCheckcolor3"
                              checked={excludeAlreadyAddedTeams}
                              onClick={(e)=>{
                                setExcludeAlreadyAddedTeams(!excludeAlreadyAddedTeams)
                              }}
                              onChange={(e) => {
                                //setExcludeAlreadyAddedTeams(!excludeAlreadyAddedTeams)
                              }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="customCheckcolor3"
                            >
                              Exclude already added teams
                            </label>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  
                  
                  <Row className="mt-4 justify-content-center">
                    <Col className="text-center">
                      <Button
                        color="secondary"
                        className="w-lg me-2"
                        onClick={(e) => {
                          closePopUp()
                        }}
                        disabled={inProgress}
                        >
                           Close
                          {inProgress && <i className="bx bx-loader bx-spin font-size-16 align-middle ms-2 me-2"></i>}
                      </Button>
                      <Button
                        color="cust-1"
                        className="w-lg "
                        onClick={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                        disabled={inProgress}
                        >
                          Save
                          {inProgress && <i className="bx bx-loader bx-spin font-size-16 align-middle ms-2 me-2"></i>}
                      </Button>
                    </Col>
                  </Row>

                </CardBody>
              </Card>
            </Col>
          </Row>
          
        </Container>
      </div>
    </React.Fragment>
  );
};

NewMatch.propTypes = {
};

export default NewMatch;
