import React, { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Badge,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Row,
} from "reactstrap";


import * as dayjs from 'dayjs'
import { secondsToHm } from "helpers/function_helper";

const Access = (cell) => {
    //console.log('cellProps.row:',cell.row)
    if(cell.value){
        return <span className="font-size-15"><i className="bx bx-check-circle text-success me-2" />Yes</span>
    }
    else{
        return <span className="font-size-15"><i className="bx bx-x-circle text-danger me-2" />No</span>
    }
};

const Teams = (cell) => {
    if(cell.value.length == 2){
        return <span> 
                    <span className="team-name">
                        {(cell.value[0].selected && cell.value[0].selected == 1) ? <i className="font-size-18 align-middle bx bxs-check-circle text-success me-2" /> : null}
                        {(cell.value[0].medal && cell.value[0].medal > 0) ? <i className={"font-size-18 mdi mdi-medal me-2 medal-color-"+cell.value[0].medal} /> : null}
                        {cell.value[0].teamName} 
                        <Badge className={"ms-2 font-size-12 badge-info"}>
                            {cell.value[0].groupName}
                        </Badge>
                    </span>{" vs "}
                    <span className="team-name">
                        {(cell.value[1].selected && cell.value[1].selected == 1) ? <i className="font-size-18 align-middle  bx bxs-check-circle text-success me-2" /> : null}
                        {(cell.value[1].medal && cell.value[1].medal > 0) ? <i className={"font-size-18 mdi mdi-medal me-2 medal-color-"+cell.value[1].medal} /> : null}
                        {cell.value[1].teamName}
                        <Badge className={"ms-2 font-size-12 badge-info"}>
                            {cell.value[1].groupName}
                        </Badge>
                    </span>
                </span>
    }
    else{
        return (
            <div>
                {cell.value.map((each,i) => (
                    <div key={i} className="team-name me-2 mb-1">
                        {(each.selected && each.selected == 1) ? <i className="font-size-18 align-middle  bx bxs-check-circle text-success me-2" /> : null}
                        {(each.medal && each.medal > 0) ? <i className={"font-size-18 mdi mdi-medal me-2 medal-color-"+each.medal} /> : null}
                        {each.teamName}
                        <Badge className={"ms-2 font-size-12 badge-info"}>
                            {each.groupName}
                        </Badge>
                    </div>
                ))}
            </div>
        )
    }
};
const Name = (cell) => {
    return cell.value ? cell.value : '';
};

const Value = (cell) => {
    return cell.value ? cell.value : '';
};

const DateAndTime = (cell) => {
    if(cell.value){
        return dayjs(cell.value).utc(true).local().format('DD-MMM-YYYY HH:mm [[]ddd]')
    }
    return cell.value ? cell.value : '';
};

const StartDate = (cell) => {
    if(cell.value){
        if(cell.row.original.endTime){
            const startTime = dayjs(cell.row.original.startTime).valueOf()
            const endTime = dayjs(cell.row.original.endTime).valueOf()
            if(endTime > startTime){
                const timeStr = secondsToHm((endTime/1000)-(startTime/1000))
                return dayjs(cell.value).utc(true).local().format('DD-MMM-YYYY HH:mm [[]ddd]') + " - " + timeStr
            }
        }
        return dayjs(cell.value).utc(true).local().format('DD-MMM-YYYY HH:mm [[]ddd]')
    }
    return cell.value ? cell.value : '';
};

const Action = (cell) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleStatusChange=(newStatus)=>{
        console.log(newStatus)
    }

    const statusOptions = ['OPEN','DONE','CANCELLED','REVERTED']
    
    return (
        <Dropdown
            isOpen={isOpen}
            toggle={() => setIsOpen(!isOpen)}
            >
            <DropdownToggle tag="button" className="btn btn-sm btn-primary">
                {cell.value} <i className="mdi mdi-chevron-down" />
            </DropdownToggle>
            <DropdownMenu>
                {statusOptions.map((each,i)=>(
                    <DropdownItem key={i} onClick={()=>handleStatusChange(each)}>{each}</DropdownItem>
                ))}
            </DropdownMenu>
        </Dropdown>
    )
};

export {
    Value,
    Access,
    Name,
    DateAndTime,
    StartDate,
    Action,
    Teams
};