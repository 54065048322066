import React, { useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import { sortBy } from "lodash";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Modal,
  Label,
  Row,
} from "reactstrap";
import {fetchDetails, insertDetails, updateDetails} from "helpers/backend_helper"
//redux
import { useSelector, useDispatch } from "react-redux";
import * as Yup from "yup";
import { useFormik } from "formik";
import Select from "react-select";
import Slide from "react-reveal/Slide";
import { changePreloader } from "../../store/actions";
import { showSuccessAlert, showFailureAlert, findChangesV2, createGroupDropDown } from "helpers/function_helper"
import AddNewGame from "./AddNewGame";
import AddNewTeam from "./AddNewTeam";
import GameTeamStats from "./GameTeamStats";
import NewMatch from "./NewMatch";
import GameMatches from "./GameMatches";
import UpdateScore from "./UpdateScore";
import UpdateCompletion from "./UpdateCompletion";
import MedalTable from "./MedalTable";
import GameRules from "./GameRules";
import Notifications from "./Notifications";
import AddNewMedia from "./AddNewMedia";
import GameTeams from "./GameTeams";


const EventLanding = props => {
  const dispatch = useDispatch();
  const { match : { params } } = props

  const [inProgress, setInProgress] = useState(false);
  const [resetValue, setResetValue] = useState(false);
  const [eventCode, setEventCode] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [selectedEventOption, setSelectedEventOption] = useState();
  const [currentEventGames, setCurrentEventGames] = useState();
  const [currentRawEventGames, setCurrentRawEventGames] = useState([]);

  const [showAddGame, setShowAddGame] = useState(false);
  const [showEventDetails, setShowEventDetails] = useState(true);
  const [showAddMedia, setShowAddMedia] = useState(false);

  const [gameStatsTriggerCounter, setGameStatsTriggerCounter] = useState(2)
  const [gameTeamsTriggerCounter, setGameTeamsTriggerCounter] = useState(2)

  const [showAddTeam, setShowAddTeam] = useState(false);
  const [showMatches, setShowMatches] = useState(false);
  const [showAddMatcheModal, setShowAddMatcheModal] = useState(false);
  const [gameMatches, setGameMatches] = useState([]);

  const [isEditMatch, setIsEditMatch] = useState(false);
  const [matchDetailsForEdit, setMatchDetailsForEdit] = useState();

  const [showScoreModal, setShowScoreModal] = useState(false);
  const [showCompletionModal, setShowCompletionModal] = useState(false);

  const [showAddRules, setShowAddRules] = useState(false);
  const [showNoticationsModal, setShowNoticationsModal] = useState(false);

  const [showEditTeamModal, setShowEditTeamModal] = useState(false);
  const [teamDetailsToEdit, setTeamDetailsToEdit] = useState();

  const [medalTally, setMedalTally] = useState([]);

  useEffect(()=>{
    if(params && params.id){
      setEventCode(params.id)
      getEventGames(params.id, null)
      getEventMedalTally(params.id)
    }
  },[])

  const getEventGames=async(id, newId)=>{
    let inputToSend = {
      "data": {
        "type": 'EVENT_GAMES',
        "event": {
          "eventCode": id,
        }
      }
    }
    const resp = await fetchDetails(inputToSend)
    if(resp && resp.status=='success'){
      if(resp.data){
        setCurrentRawEventGames(resp.data)
        const finalDD = createGroupDropDown(resp.data)
        setCurrentEventGames(finalDD)
        if(newId){
          finalDD.forEach(eachOpt => {
            const currSelOption = eachOpt.options.filter(each => each.id == newId)
            if(currSelOption && currSelOption.length == 1){
              setTimeout(() => {
                setSelectedEventOption(currSelOption[0])
              }, 100);
            }
          })
        }
      }
      //let tempTempResp = resp.data
      //validation.setValues(resp.data)
      //setCurrentUserDetails(tempTempResp)
      //validation.validateForm()
    }
  }

  const getEventMedalTally=async(id)=>{
    let inputToSend = {
      "data": {
        "type": 'MEDAL_TALLY',
        "event": {
          "eventCode": id,
        }
      }
    }
    const resp = await fetchDetails(inputToSend)
    if(resp && resp.status=='success'){
      if(resp.data){
        const sortedTeams = sortBy(resp.data,['gold','silver','bronze']).reverse()
        setMedalTally(sortedTeams)
      }
    }
  }

  const handleEventChange=(option)=>{
    console.log('Sel Evnt:',option)
    setSelectedEventOption(option)
  }

  useEffect(()=>{
    if(selectedEventOption && selectedEventOption.id){
      getMatchesForGame(selectedEventOption.id)
    }
  },[selectedEventOption])

  const getMatchesForGame=async(id)=>{
    let inputToSend = {
      "data": {
        "type": 'GAME_MATCHES',
        "game": {
          "gameId": id,
        }
      }
    }
    const resp = await fetchDetails(inputToSend)
    if(resp && resp.status=='success'){
      if(resp.data){
        setGameMatches(resp.data)
      }
    }
  }

  const onSelect=(option)=>{
    //console.log('option:',option)
    validation.setFieldValue(option.objKey,option.selectedOption)
  }

  const validation = useFormik({
    enableReinitialize: false,
    initialValues: {
      eventName: '',
    },
    validationSchema: Yup.object({
      eventName: Yup.string().trim().required("Please enter a valid Event Name."),
    }),
    onSubmit: (values) => {
      saveDetails(values)
    }
  });

  const saveDetails = async(values)=>{
    setInProgress(true)
    dispatch(changePreloader(true))
    if(isEdit){
      const changes = findChangesV2({...currentUserDetails}, values ,[])
      if(changes.length == 0){
        showFailureAlert('Nothing to Update...')
        setInProgress(false)
        dispatch(changePreloader(false))
        return
      }
      let inputToSend = {
        "data": {
          "type": 'UPDATE_EVENT',
          "event":{
            ...values,
            log: changes,
          }
        }
      }
      const resp = await updateDetails(inputToSend)
      if(resp && resp.status == 'success'){
        showSuccessAlert('Updated Successfully!')
      }
      else{
        if(resp.error && resp.error.msg){
          showFailureAlert(resp.error.msg)
        }
        else{
          showFailureAlert('Unknown failure')
        }
      }
    }
    else{
      let inputToSend = {
        "data": {
          "type": 'NEW_EVENT',
          "event":{
            ...values
          }
        }
      }
      const resp = await insertDetails(inputToSend)
      //console.log('resp:',resp)
      if(resp && resp.status == 'success'){
        showSuccessAlert('Added Successfully!')
        validation.resetForm()
      }
    }

    setInProgress(false)
    dispatch(changePreloader(false))
  }

  const onConfirmAdd=(id)=>{
    if(id){
      getEventGames(params.id, id)
    }
    setShowEventDetails(true)
    setShowAddGame(false)
    
  }

  const onClickAddGame=()=>{
    setShowEventDetails(false)
    setShowAddGame(true)
  }

  const onClickAddTeam=()=>{
    //setShowEventDetails(false)
    setShowAddTeam(true)
    setShowAddGame(false)
  }

  
  const onConfirmAddMedia=()=>{
    setShowEventDetails(true)
    setShowAddMedia(false)
  }
  const onClickAddMedia=()=>{
    setShowEventDetails(false)
    setShowAddMedia(true)
  }

  const onConfirmAddTeam=(id)=>{
    if(id){
      setGameStatsTriggerCounter(id)
      //getEventGames(params.id, id)
    }
    setShowAddTeam(false)
  }

  const onConfirmAddScore=(id)=>{
    getMatchesForGame(selectedEventOption.id)
    toggle_scoresModal()
  }

  const onConfirmUpdate=(id)=>{
    getMatchesForGame(selectedEventOption.id)
    //toggle_scoresModal()
  }

  const onConfirmUpdateResult=(id)=>{
    getMatchesForGame(selectedEventOption.id)
    toggle_completionModal()
  }
  

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  function toggle_addRulesModal() {
    setShowAddRules(!showAddRules);
    removeBodyCss();
  }

  function toggle_noticationsModal() {
    setShowNoticationsModal(!showNoticationsModal);
    removeBodyCss();
  }
  
  function toggle_addMatchesModal() {
    if(showAddMatcheModal){
      setIsEditMatch(false)
    }
    setShowAddMatcheModal(!showAddMatcheModal);
    removeBodyCss();
  }

  function toggle_scoresModal() {
    setShowScoreModal(!showScoreModal);
    removeBodyCss();
  }
  function toggle_completionModal() {
    setShowCompletionModal(!showCompletionModal);
    removeBodyCss();
  }
  function toggle_editTeamModal() {
    setShowEditTeamModal(!showEditTeamModal);
    removeBodyCss();
  }

  const onConfirmEditTeam=()=>{
    toggle_editTeamModal()
    setGameTeamsTriggerCounter(gameTeamsTriggerCounter+1)
  }

  const onAdditionOFMatchSuccess=()=>{
    if(selectedEventOption && selectedEventOption.id){
      getMatchesForGame(selectedEventOption.id)
    }
    toggle_addMatchesModal()
  }

  const handleEditClickT=(data)=>{
    setIsEditMatch(true)
    setMatchDetailsForEdit(data)
    toggle_addMatchesModal()
    //handleEditClick(data)
  }
  const handleScoreClick=(data)=>{
    setMatchDetailsForEdit(data)
    toggle_scoresModal()
    //handleScoreClick(data)
  }
  const handleScoreEditClick=(data)=>{
    setMatchDetailsForEdit(data)
    toggle_scoresModal()
    //handleScoreClick(data)
  }
  const handleMarkAsCompleteClick=(data)=>{
    setMatchDetailsForEdit(data)
    toggle_completionModal()
  }
  const handleDeleteClick=(data)=>{
    //handleDeleteClick(data)
  }
  
  const handleTeamsEditClick=(data)=>{
    setTeamDetailsToEdit(data)
    setShowEditTeamModal(true)
  }
  
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          {showEventDetails && 
          <>
          <Slide duration={300} right opposite>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row>
                    <Col xs="12" md={5}>
                      <MedalTable medals={medalTally}/>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          </Slide>

          <Slide duration={300} right opposite>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row>
                    <Col xs="12">
                      <div className=" d-flex align-items-center justify-content-between">
                        <span className="page-heading">Games</span>
                        <div className="page-title-right">
                          <Button
                            color="cust-1"
                            className="w-lg me-2"
                            onClick={(e) => {
                              onClickAddGame()
                            }}
                            disabled={inProgress}
                            >
                              Add Game
                          </Button>
                          <Button
                            color="cust-1"
                            className="w-lg me-2"
                            onClick={(e) => {
                              toggle_noticationsModal()
                            }}
                            disabled={inProgress}
                            >
                              Send Notification
                          </Button>
                          <Button
                            color="cust-1"
                            className="w-lg me-2"
                            onClick={(e) => {
                              onClickAddMedia()
                            }}
                            disabled={inProgress}
                            >
                              Add Media
                          </Button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row className="mt-3 align-items-center mb-3">
                    <Col xs={12} md={12}>
                      <Row>
                        <Col xs={12} md={2} className="text-sm-start text-md-end">
                          <Label
                            htmlFor="name"
                            className="col-form-label"
                          >
                            Select Game :
                          </Label>
                        </Col>
                        <Col xs={12} md={4} className="">
                          <Select
                            value={selectedEventOption}
                            onChange={s => {
                              handleEventChange(s)
                            }}
                            isLoading={inProgress}
                            isDisabled={false}
                            isMulti={false}
                            options={currentEventGames}
                            placeholder="Select..."
                            classNamePrefix="select2-selection"
                            menuPortalTarget={document.body}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          </Slide>

          {!showAddTeam && selectedEventOption &&
          <Slide duration={300} right opposite>
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <Row>
                      <Col xs="12">
                        <div className=" d-flex align-items-center justify-content-between">
                          <span className="page-heading">Teams</span>
                          <div className="page-title-right">
                            <Button
                              color="cust-1"
                              className="w-lg me-2"
                              onClick={(e) => {
                                toggle_addRulesModal()
                              }}
                              disabled={inProgress}
                              >
                                Add Rule
                            </Button>
                            <Button
                              color="cust-1"
                              className="w-lg "
                              onClick={(e) => {
                                onClickAddTeam()
                              }}
                              disabled={inProgress}
                              >
                                Add Team
                            </Button>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="4">
                        <GameTeamStats eventCode={eventCode} gameId={selectedEventOption.gameCatId} gameStatsTriggerCounter={gameStatsTriggerCounter}/>
                      </Col>
                      <Col md="8">
                        <GameTeams 
                          gameTeamsTriggerCounter={gameTeamsTriggerCounter}
                          eventCode={eventCode} 
                          gameCatId={selectedEventOption.gameCatId} 
                          matchType={selectedEventOption.matchType} 
                          gameId={selectedEventOption.id}
                          handleEditClick={handleTeamsEditClick}
                        />
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Slide>
          }
          {showAddTeam && <AddNewTeam eventCode={eventCode} selectedEventOption={selectedEventOption} onConfirmAddTeam={onConfirmAddTeam}/>}

          {!showMatches && selectedEventOption &&
          <Slide duration={300} right opposite>
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <Row>
                      <Col xs="12">
                        <div className=" d-flex align-items-center justify-content-between">
                          <span className="page-heading">Matches</span>
                          <div className="page-title-right">
                            <Button
                              color="cust-1"
                              className="w-lg "
                              onClick={(e) => {
                                toggle_addMatchesModal()
                              }}
                              disabled={inProgress}
                              >
                                Add Match
                            </Button>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <GameMatches 
                          matches={gameMatches} 
                          handleEditClick={handleEditClickT} 
                          handleScoreClick={handleScoreClick} 
                          handleDeleteClick={handleDeleteClick} 
                          handleScoreEditClick={handleScoreEditClick}
                          handleMarkAsCompleteClick={handleMarkAsCompleteClick}
                        />
                      </Col>
                    </Row>
                    
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Slide>
          }

          </>
          }

          {showAddGame && <AddNewGame eventCode={eventCode} onConfirmAdd={onConfirmAdd} existingGames={currentRawEventGames}/>}
          {showAddMedia && <AddNewMedia eventCode={eventCode} onConfirmAddMedia={onConfirmAddMedia}/>}

          <Row style={{minHeight:"200px"}}>

          </Row>
          
        </Container>
      </div>

      {selectedEventOption &&
      <Modal
        size="xl"
        isOpen={showAddMatcheModal}
        toggle={() => {
          toggle_addMatchesModal()
        }}
      >
        <div className="modal-header">
          <h5
            className="modal-title mt-0"
            id="myExtraLargeModalLabel"
          >
            Configure
          </h5>
          <button
            onClick={() => {
              toggle_addMatchesModal();
            }}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <NewMatch 
            existingMatches={gameMatches} 
            eventCode={eventCode} 
            gameCatId={selectedEventOption.gameCatId} 
            matchType={selectedEventOption.matchType} 
            gameId={selectedEventOption.id} 
            onClosePopUp={()=>toggle_addMatchesModal()}
            onAdditionSuccess={()=>onAdditionOFMatchSuccess()}
            matchDetailsForEdit={matchDetailsForEdit}
            isEditMatch={isEditMatch}
          />
        </div>
      </Modal>
      }

      {selectedEventOption && showAddRules &&
      <Modal
        size="xl"
        isOpen={showAddRules}
        toggle={() => {
          toggle_addRulesModal()
        }}
      >
        <div className="modal-header">
          <h5
            className="modal-title mt-0"
            id="myExtraLargeModalLabel"
          >
            Rules
          </h5>
          <button
            onClick={() => {
              toggle_addRulesModal();
            }}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <GameRules
            eventCode={eventCode}
            gameId={selectedEventOption.id} 
            onClosePopUp={()=>toggle_addRulesModal()}
            onAdditionSuccess={()=>toggle_addRulesModal()}
          />
        </div>
      </Modal>
      }

      {showNoticationsModal &&
      <Modal
        size="xl"
        isOpen={showNoticationsModal}
        toggle={() => {
          toggle_noticationsModal()
        }}
      >
        <div className="modal-header">
          <h5
            className="modal-title mt-0"
            id="myExtraLargeModalLabel"
          >
            Notifications
          </h5>
          <button
            onClick={() => {
              toggle_noticationsModal();
            }}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Notifications
            eventCode={eventCode}
            onClosePopUp={()=>toggle_noticationsModal()}
            onAdditionSuccess={()=>toggle_noticationsModal()}
          />
        </div>
      </Modal>
      }

      {showScoreModal && 
      <Modal
        size="xl"
        isOpen={showScoreModal}
        toggle={() => {
          toggle_scoresModal()
        }}
      >
        <div className="modal-header">
          <h5
            className="modal-title mt-0"
            id="myExtraLargeModalLabel"
          >
            Update
          </h5>
          <button
            onClick={() => {
              toggle_scoresModal();
            }}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <UpdateScore 
            onConfirmAddScore={()=>onConfirmAddScore()}
            matchDetails={matchDetailsForEdit}
            onClosePopUp={()=>toggle_scoresModal()}
            onConfirmUpdate={()=>onConfirmUpdate()}
            />
        </div>
      </Modal>
      }

    {showCompletionModal && 
      <Modal
        size="xl"
        isOpen={showCompletionModal}
        toggle={() => {
          toggle_completionModal()
        }}
      >
        <div className="modal-header">
          <h5
            className="modal-title mt-0"
            id="myExtraLargeModalLabel"
          >
            Update
          </h5>
          <button
            onClick={() => {
              toggle_completionModal();
            }}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <UpdateCompletion 
            matchDetails={matchDetailsForEdit}
            onClosePopUp={()=>toggle_completionModal()}
            onConfirmUpdateResult={()=>onConfirmUpdateResult()}
            />
        </div>
      </Modal>
      }

    {showEditTeamModal && 
      <Modal
        size="xl"
        isOpen={showEditTeamModal}
        toggle={() => {
          toggle_editTeamModal()
        }}
      >
        <div className="modal-header">
          <h5
            className="modal-title mt-0"
            id="myExtraLargeModalLabel"
          >
            Update
          </h5>
          <button
            onClick={() => {
              toggle_editTeamModal();
            }}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <AddNewTeam 
            eventCode={eventCode} 
            teamDetailsToEdit={teamDetailsToEdit}
            selectedEventOption={selectedEventOption} 
            onConfirmEditTeam={()=>onConfirmEditTeam()}
          />
        </div>
      </Modal>
      }

    </React.Fragment>
  );
};

EventLanding.propTypes = {
};

export default EventLanding;
