import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { differenceBy } from "lodash";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap";
import {fetchDetails, insertDetails, updateDetails} from "helpers/backend_helper"
//redux
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import * as Yup from "yup";
import { useFormik } from "formik";
import CustomCreatableSelect from "components/Common/CustomCreatableSelect"
import { changePreloader } from "../../store/actions";
import { showSuccessAlert, showFailureAlert, findChangesV2 } from "helpers/function_helper"
import "flatpickr/dist/themes/airbnb.css"
import Flatpickr from "react-flatpickr"
import * as dayjs from 'dayjs'

const GameRules = props => {
  const dispatch = useDispatch();

  const [inProgress, setInProgress] = useState(false);

  const [allRules, setAllRules] = useState([]);

  useEffect(()=>{
    if(props.gameId){
      getAllRules(props.gameId)
    }
    
  },[props.gameId])

  const getAllRules=async(gameId)=>{
    setInProgress(true)
    let inputToSend = {
      "data": {
        "type": 'ALL_RULES',
        "game": {
          "gameId": gameId,
        }
      }
    }
    const resp = await fetchDetails(inputToSend)
    if(resp && resp.status=='success'){
      if(resp.data){
        setAllRules(resp.data)
      }
    }
    setInProgress(false)
  }


  const validation = useFormik({
    enableReinitialize: false,
    initialValues: {
      gameId: props.gameId,
      ruletitle: '',
      rules: '',
    },
    validationSchema: Yup.object({
      ruletitle: Yup.string().trim().required("Please enter the title."),
      rules: Yup.string().trim().required("Please enter the rules.")
    }),
    onSubmit: (values) => {
      //console.log('values:',values)
      saveDetails(values)
    }
  });

  const saveDetails = async(values)=>{
    setInProgress(true)
    dispatch(changePreloader(true))

    let inputToSend = {
      "data": {
        "type": 'NEW_RULE',
        "rules":{
          gameId: values.gameId,
          ruleTitle: values.ruletitle,
          rules: values.rules
        }
      }
    }
    const resp = await insertDetails(inputToSend)
    //console.log('resp:',resp)
    if(resp && resp.status == 'success'){
      showSuccessAlert('Added Successfully!')
      props.onAdditionSuccess()
    }

    setInProgress(false)
    dispatch(changePreloader(false))
  }

  const closePopUp=()=>{
    props.onClosePopUp()
  }

  function updateIsEditFalseForAll() {
    var modifiedRows = [...allRules]
      modifiedRows = modifiedRows.map(eachRow => {
        return {
          ...eachRow,
          isEdit: false,
        }
    })
    setAllRules(modifiedRows)
  }

  function handleInputTextChange(id, key, value) {
    var modifiedRows = [...allRules]
      modifiedRows = modifiedRows.map(eachRow => {
      if(eachRow["id"] == id){
        //eachRow["productrate"] = value
        return {
          ...eachRow,
          [key]: value,
        }
      }
      else {
        return eachRow
      }
    })
    setAllRules(modifiedRows)
  }

  async function handleSave(editedRow){
    setInProgress(true)
    dispatch(changePreloader(true))
    if(editedRow && editedRow.ruleTitle != '' && editedRow.rules != ''){
      //const changes = findChangesV2(props.matchDetailsForEdit, values ,[])
      //console.log('changes?',changes)
      let inputToSend = {
        "data": {
          "type": 'UPDATE_RULE',
          "rules":{
            ...editedRow,
            gameId: props.gameId,
          }
        }
      }
      const resp = await updateDetails(inputToSend)
      //console.log('resp:',resp)
      if(resp && resp.status == 'success'){
        showSuccessAlert('Updated Successfully!')
        updateIsEditFalseForAll()
      }
    }
    setInProgress(false)
    dispatch(changePreloader(false))
  }

  async function handleDeleteRule(editedRow){
    
    setInProgress(true)
    dispatch(changePreloader(true))
    if(editedRow && editedRow.ruleTitle != '' && editedRow.rules != ''){
      //const changes = findChangesV2(props.matchDetailsForEdit, values ,[])
      //console.log('changes?',changes)
      let inputToSend = {
        "data": {
          "type": 'DELETE_RULE',
          "rules":{
            ...editedRow,
            gameId: props.gameId,
          }
        }
      }
      const resp = await updateDetails(inputToSend)
      //console.log('resp:',resp)
      if(resp && resp.status == 'success'){
        showSuccessAlert('Deleted Successfully!')
        props.onAdditionSuccess()
      }
    }
    setInProgress(false)
    dispatch(changePreloader(false))
  }

  return (
    <React.Fragment>
      <div className="">
        <Container fluid={true}>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row className="text-center mb-2">
                    <Col>
                      <span className="page-heading">Rules</span>
                    </Col>
                  </Row>
                
                  {allRules && allRules.length > 0 && allRules.map((each,idx)=> (
                    <Row className="mt-3 align-items-center" key={'rl'+idx}>
                      <Col xs={12} md={12}>
                        <Row>
                          <Col xs={12} md={3} className="text-sm-start text-md-end">
                            <Input
                              name="ruletitle"
                              type="text"
                              maxLength={100}
                              disabled={!each.isEdit}
                              placeholder="Rule Heading"
                              onChange={e=>{
                                handleInputTextChange(each.id,'ruleTitle',e.target.value)
                              }} 
                              value={allRules.filter(x=>x["id"]==each.id)[0].ruleTitle}
                            />
                          </Col>
                          <Col xs={12} md={8} className="">
                            <Input
                                name="rules"
                                type="textarea"
                                rows="5"
                                disabled={!each.isEdit}
                                maxLength={1200}
                                placeholder="Rules, separated by new line"
                                onChange={e=>{
                                  handleInputTextChange(each.id,'rules',e.target.value)
                                }} 
                                value={allRules.filter(x=>x["id"]==each.id)[0].rules}
                              />
                          </Col>
                          <Col xs={12} md={1} className="">
                            {!each.isEdit && 
                            <Button
                              color="secondary"
                              className="mb-2"
                              onClick={(e) => {
                                handleInputTextChange(each.id,'isEdit',true)
                              }}
                              disabled={inProgress}
                              >
                                Edit
                              {inProgress && <i className="bx bx-loader bx-spin font-size-16 align-middle ms-2 me-2"></i>}
                            </Button>
                            }
                            {each.isEdit && 
                            <Button
                              color="primary"
                              className="me-2 mb-2"
                              onClick={(e) => {
                                handleSave(each)
                              }}
                              disabled={inProgress}
                              >
                                Save
                              {inProgress && <i className="bx bx-loader bx-spin font-size-16 align-middle ms-2 me-2"></i>}
                            </Button>
                            }
                            {each.isEdit && 
                            <Button
                              color="danger"
                              className="me-2"
                              onClick={(e) => {
                                handleDeleteRule(each)
                              }}
                              disabled={inProgress}
                              >
                                Delete Rule
                              {inProgress && <i className="bx bx-loader bx-spin font-size-16 align-middle ms-2 me-2"></i>}
                            </Button>
                            }
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  ))
                  }
                  
                  
                  <Row className="mt-3 align-items-center">
                    <Col xs={12} md={12}>
                      <Row>
                        <Col xs={12} md={3} className="text-sm-start text-md-end">
                          <Input
                            name="ruletitle"
                            type="text"
                            maxLength={100}
                            placeholder="Rule Heading"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.ruletitle || ""}
                            invalid={
                              validation.touched.ruletitle && validation.errors.ruletitle ? true : false
                            }
                          />
                          {validation.touched.ruletitle && validation.errors.ruletitle ? (
                            <FormFeedback type="invalid">{validation.errors.ruletitle}</FormFeedback>
                          ) : null}
                        </Col>
                        <Col xs={12} md={8} className="">
                          <Input
                              name="rules"
                              type="textarea"
                              rows="5"
                              maxLength={1200}
                              placeholder="Rules, separated by new line"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.rules || ""}
                              invalid={
                                validation.touched.rules && validation.errors.rules ? true : false
                              }
                            />
                          {validation.touched.rules && validation.errors.rules ? (
                            <FormFeedback type="invalid">{validation.errors.rules}</FormFeedback>
                          ) : null}
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  
                  <Row className="mt-4 justify-content-center">
                    <Col className="text-center">
                      <Button
                        color="secondary"
                        className="w-lg me-2"
                        onClick={(e) => {
                          closePopUp()
                        }}
                        disabled={inProgress}
                        >
                           Close
                          {inProgress && <i className="bx bx-loader bx-spin font-size-16 align-middle ms-2 me-2"></i>}
                      </Button>
                      <Button
                        color="cust-1"
                        className="w-lg "
                        onClick={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                        disabled={inProgress}
                        >
                          Save
                          {inProgress && <i className="bx bx-loader bx-spin font-size-16 align-middle ms-2 me-2"></i>}
                      </Button>
                    </Col>
                  </Row>

                </CardBody>
              </Card>
            </Col>
          </Row>
          
        </Container>
      </div>
    </React.Fragment>
  );
};

GameRules.propTypes = {
};

export default GameRules;
