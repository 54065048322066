import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { differenceBy, map } from "lodash";
import {
  Button,
  Card,
  CardBody,
  Col,
  UncontrolledTooltip,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
  Table,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";
import {fetchDetails, insertDetails, updateDetails} from "helpers/backend_helper"
import { Link } from "react-router-dom"
//redux
import { useSelector, useDispatch } from "react-redux";
import Slide from "react-reveal/Slide";
import Select from "react-select";
import { changePreloader } from "../../store/actions";
import { uploadImages, 
  deleteFile, 
  showFailureAlert, 
  getFormattedDateAndTimeFromMillisInSGT, 
  showCloseableToast, 
  getOnlyFileName, 
  findChangesV2 } from "helpers/function_helper"
import * as Yup from "yup";
import { useFormik } from "formik";
import Dropzone from "react-dropzone"
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"

const AddNewMedia = props => {
  const dispatch = useDispatch();

  const [inProgress, setInProgress] = useState(false);
  const [selectedEventOption, setSelectedEventOption] = useState();
  const [allMedia, setAllMedia] = useState([]);
  const [showAllMedia, setShowAllMedia] = useState(false);
  const [isEdit, setIsEdit] = useState(false)
  const [allMediaReference, setAllMediaReference] = useState([]);

  const [selectedFiles, setSelectedFiles] = useState([])
  const [isGallery, setisGallery] = useState(false)
  const [photoIndex, setphotoIndex] = useState(0)
  const [btnDeleteMedia, setBtnDeleteMedia] = useState(false)

  useEffect(()=>{
    //getEventGames()
  },[])

  const validation = useFormik({
    enableReinitialize: false,
    initialValues: {
      id:'',
      eventCode: props.eventCode,
      title: '',
      description: '',
      images:[]
    },
    validationSchema: Yup.object({
      title: Yup.string().trim().required("Please enter the title."),
      images: Yup.array().required().min(1,"Please select 1 media.")
    }),
    onSubmit: (values) => {
      //console.log('values:',values)
      addNewMediaToEvent(values)
    }
  });

  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )

    let updatedList = [];
    selectedFiles.forEach(eachExistingFile => {
      const found = files.find(newFile => newFile.path === eachExistingFile.path)
      if(!found){
        updatedList.push(eachExistingFile)
      }
    })
    files.forEach(eachNewItem => updatedList.push(eachNewItem))
    setSelectedFiles(updatedList)
    validation.setFieldValue('images',updatedList)
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  const removeFile = (file) => {
    const name = file.name
    if(isEdit && file.id){
      removeExistingFromServer(file)
    }
    const index = selectedFiles.findIndex(e => e.path === name);
    selectedFiles.splice(index, 1);
    setSelectedFiles([...selectedFiles]);
    validation.setFieldValue('images',[...selectedFiles])
  }

  const removeExistingFromServer=async(file)=>{
    console.log('file:',file)
    deleteFile(file.name).then(result=>{
      console.log('result:',result)
      handleDeleteImage(file.id)
    })
  }

  const getAllMedia=async()=>{
    setInProgress(true)
    dispatch(changePreloader(true))
    let inputToSend = {
      "data": {
        "type": 'ALL_MEDIA',
        "game": {
          "eventCode": props.eventCode,
        }
      }
    }
    const resp = await fetchDetails(inputToSend)
    if(resp && resp.status=='success'){
      //setAllEventGames(resp.data)
      setShowAllMedia(true)
      if(resp.data && resp.data.length > 0){
        setAllMedia(resp.data)
        setAllMediaReference([...resp.data])
      }
    }
    setInProgress(false)
    dispatch(changePreloader(false))
  }

  const onClickViewExisting=()=>{
    getAllMedia()
  }
  
  const onConfirmDeleteMedia=async()=>{
    setInProgress(true)
    dispatch(changePreloader(true))
    if(validation.values.id){
      let inputToSend = {
        "data": {
          "type": 'DELETE_MEDIA',
          "media":{
            id: validation.values.id,
          }
        }
      }
      const resp = await updateDetails(inputToSend)
      if(resp && resp.status == 'success'){
        //showSuccessAlert('Deleted Successfully!')
        showCloseableToast('Deleted Successfully', 'top-right','success')
        onClickViewExisting()
      }
    }
    setInProgress(false)
    dispatch(changePreloader(false))
  }

  async function handleDeleteImage(imageId){
    
    setInProgress(true)
    dispatch(changePreloader(true))
    if(imageId){
      //const changes = findChangesV2(props.matchDetailsForEdit, values ,[])
      //console.log('changes?',changes)
      let inputToSend = {
        "data": {
          "type": 'DELETE_IMAGE',
          "media":{
            id: imageId,
          }
        }
      }
      const resp = await updateDetails(inputToSend)
      //console.log('resp:',resp)
      if(resp && resp.status == 'success'){
        //showSuccessAlert('Deleted Successfully!')
        showCloseableToast('Deleted Successfully', 'top-right','success')
        //props.onAdditionSuccess()
      }
    }
    setInProgress(false)
    dispatch(changePreloader(false))
  }

  const updateMediaDetails=async(values, files)=>{
    let inputToSend = {
      "data": {
        "type": 'UPDATE_MEDIA',
        "media":{
          id: values.id,
          eventCode: props.eventCode,
          title: values.title,
          shortDescription : values.description,
          images: files
        }
      }
    }
    updateDetails(inputToSend).then(updatedStatus => {
      if(updatedStatus && updatedStatus.status == 'success'){
        props.onConfirmAddMedia()
        showCloseableToast('Updated Successfully', 'top-right','success')
      }
      else{
        if(updatedStatus.error && updatedStatus.error.msg){
          showFailureAlert(updatedStatus.error.msg)
        }
        else{
          showFailureAlert('Unknown failure')
        }
      }
    })
  }

  const addNewMediaToEvent=async(values)=>{

    
    setInProgress(true)
    dispatch(changePreloader(true))

    if(isEdit){
      const newFiles = values.images.filter(each => each.id == undefined)
      console.log('newFiles:',newFiles)
      if(newFiles && newFiles.length > 0){
        const resp = await uploadImages(newFiles).then(resp => {
          console.log('resp:::',resp)
          if(resp && resp.length > 0){
            const successFiles = resp.filter(each => each.status == 'success')
            console.log('successFiles:',successFiles)
            updateMediaDetails(values,successFiles)
          }
        })
      }
      else{
        updateMediaDetails(values,[])
      }
    }
    else{
      const resp = await uploadImages(selectedFiles).then(resp => {
        console.log('resp:::',resp)
        if(resp && resp.length > 0){
          const successFiles = resp.filter(each => each.status == 'success')
          console.log('successFiles:',successFiles)
    
          let inputToSend = {
            "data": {
              "type": 'NEW_MEDIA',
              "media":{
                eventCode: props.eventCode,
                title: values.title,
                shortDescription : values.description,
                images: successFiles
              }
            }
          }
          insertDetails(inputToSend).then(updatedStatus => {
            if(updatedStatus && updatedStatus.status == 'success'){
              props.onConfirmAddMedia()
              showCloseableToast('Added Successfully', 'top-right','success')
            }
            else{
              if(updatedStatus.error && updatedStatus.error.msg){
                showFailureAlert(updatedStatus.error.msg)
              }
              else{
                showFailureAlert('Unknown failure')
              }
            }
          })
        }
      })
    }

    setInProgress(false)
    dispatch(changePreloader(false))
  }

  const cancelAddMedia=()=>{
    props.onConfirmAddMedia()
  }

  const onClickEditMedia=async(media)=>{
    validation.setValues(media)
    validation.setFieldValue('description',media.shortDescription)
    setIsEdit(true)
    media.images.map(file =>
      Object.assign(file, {
        preview: (file.imageUrl),
        name: getOnlyFileName(file.imageUrl)
        //formattedSize: formatBytes(file.size),
      })
    )
    setSelectedFiles(media.images)
    setShowAllMedia(false)
  }

  return (
    <React.Fragment>
      {!showAllMedia && 
      <Slide duration={300} right opposite>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <Row>
                <Col xs="12">
                  <div className=" d-flex align-items-center justify-content-between">
                    <span className="page-heading">Add Media</span>
                    <div className="page-title-right">
                      
                      <Button
                        color="cust-1"
                        className="w-lg me-2"
                        onClick={(e) => {
                          onClickViewExisting()
                        }}
                        disabled={inProgress}
                        >
                          View Existing
                      </Button>
                      {isEdit && 
                      <Dropdown
                        className="mt-1 inline"
                        isOpen={btnDeleteMedia}
                        toggle={() => setBtnDeleteMedia(!btnDeleteMedia)}
                      >
                        <DropdownToggle tag="button" className="btn btn-danger">
                          Delete <i className="mdi mdi-chevron-down" />
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem onClick={()=>onConfirmDeleteMedia()}>Confirm Delete</DropdownItem>
                          <DropdownItem onClick={()=>setBtnDeleteMedia(!btnDeleteMedia)}>Close</DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                      }
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className="mt-3 align-items-center">
                <Col xs={12} md={12}>
                  <Row>
                    <Col xs={12} md={2} className="text-sm-start text-md-end">
                      <Label
                        htmlFor="title"
                        className="col-form-label"
                      >
                        Title :
                      </Label>
                    </Col>
                    <Col xs={12} md={8} className="">
                      <Input
                        maxLength={20}
                        type="text"
                        name="title"
                        className="form-control"
                        id="title"
                        placeholder="title"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.title || ""}
                        invalid={
                          validation.touched.title && validation.errors.title ? true : false
                        }
                      />
                      {validation.touched.title && validation.errors.title ? (
                        <FormFeedback type="invalid">{validation.errors.title}</FormFeedback>
                      ) : null}
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="mt-3 align-items-center">
                <Col xs={12} md={12}>
                  <Row>
                    <Col xs={12} md={2} className="text-sm-start text-md-end">
                      <Label
                        htmlFor="description"
                        className="col-form-label"
                      >
                        Details :
                      </Label>
                    </Col>
                    <Col xs={12} md={8} className="">
                      <Input
                        maxLength={500}
                        type="textarea"
                        rows="2"
                        name="description"
                        className="form-control"
                        id="description"
                        placeholder="description"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.description || ""}
                        invalid={
                          validation.touched.description && validation.errors.description ? true : false
                        }
                      />
                      {validation.touched.description && validation.errors.description ? (
                        <FormFeedback type="invalid">{validation.errors.description}</FormFeedback>
                      ) : null}
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row className="mt-3 align-items-center">
                <Col xs={12} md={12}>
                  <Row>
                    <Col xs={12} md={2} className="text-sm-start text-md-end">
                      <Label
                        htmlFor="media"
                        className="col-form-label"
                      >
                        Media :
                      </Label>
                    </Col>
                    <Col xs={12} md={8} className="">
                      <Form>
                        <Dropzone
                          onDrop={acceptedFiles => {
                            handleAcceptedFiles(acceptedFiles)
                          }}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div className="dropzone">
                              <div
                                className="dz-message needsclick mt-2"
                                {...getRootProps()}
                              >
                                <input {...getInputProps()} />
                                <div className="mb-3">
                                  <i className="display-4 text-muted bx bxs-cloud-upload" />
                                </div>
                                <h4>Drop files here or click to upload.</h4>
                              </div>
                            </div>
                          )}
                        </Dropzone>
                        {validation && validation.errors && validation.touched.images && validation.errors.images && 
                            <span className="text-danger ms-1"><small>{validation.errors.images}</small></span>
                          }
                        <div className="dropzone-previews mt-3" id="file-previews">
                          {selectedFiles.map((f, i) => {
                            return (
                              <Card
                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                key={i + "-file"}
                              >
                                <div className="p-2">
                                  <Row className="align-items-center">
                                    <Col className="col-auto">
                                      <img
                                        data-dz-thumbnail=""
                                        height="80"
                                        className="avatar-sm rounded bg-light"
                                        alt={f.name}
                                        src={f.preview}
                                      />
                                    </Col>
                                    <Col>
                                    <Link
                                      to="#"
                                      className="text-muted font-weight-bold"
                                      onClick={() => {
                                        setphotoIndex(i)
                                        setisGallery(true)
                                      }}
                                    >
                                      {f.name}
                                    </Link>
                                      <Button
                                        color="danger"
                                        className="btn btn-rounded btn-sm ms-2"
                                        onClick={() => {
                                          removeFile(f)
                                        }}
                                      >
                                        <i className="fas fa-trash-alt"></i> 
                                      </Button>
                                      <p className="mb-0">
                                        <strong>{f.formattedSize}</strong>
                                      </p>
                                    </Col>
                                  </Row>
                                </div>
                              </Card>
                            )
                          })}
                        </div>
                      </Form>
                    </Col>
                  </Row>
                </Col>
              </Row>
              
              <Row className="mt-4 justify-content-center">
                <Col className="text-center">
                  <Button
                    color="secondary"
                    className="w-lg me-2"
                    onClick={(e) => {
                      cancelAddMedia()
                    }}
                    disabled={inProgress}
                    >
                      CANCEL
                  </Button>
                  <Button
                    color="cust-1"
                    className="w-lg "
                    onClick={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                      //addNewMediaToEvent()
                    }}
                    disabled={inProgress}
                    >
                      {isEdit ? 'UPDATE' : 'ADD' }
                      {inProgress && <i className="bx bx-loader bx-spin font-size-16 align-middle ms-2 me-2"></i>}
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      </Slide>}
      {showAllMedia &&
      <Slide duration={300} right opposite>
      <div className="table-responsive">
        <Row>
          <Col xs="12">
            <div className=" d-flex align-items-center justify-content-between">
              <span className="page-heading">All Media</span>
              <div className="page-title-right">
                <Button
                  color="cust-1"
                  className="w-lg me-2"
                  onClick={(e) => {
                    setIsEdit(false)
                    setShowAllMedia(false)
                    validation.setValues({
                      id:'',
                      eventCode: props.eventCode,
                      title: '',
                      description: '',
                      images:[]
                    })
                    setSelectedFiles([])
                  }}
                  disabled={inProgress}
                  >
                    Close
                </Button>
              </div>
            </div>
          </Col>
        </Row>
        <Table className="project-list-table table-nowrap align-middle table-borderless">
          <thead>
            <tr>
              <th scope="col" style={{ width: "100px" }}>
                #
              </th>
              <th scope="col">Details</th>
              <th scope="col">Date</th>
              <th scope="col">Images</th>
              <th scope="col">Edit</th>
            </tr>
          </thead>
          <tbody>
            {map(allMedia, (media, index) => (
              <tr key={index}>
                <td className="text-muted">
                    {media.id}
                </td>
                <td>
                  <h5 className="text-truncate font-size-14">
                      {media.title}
                  </h5>
                  <p className="text-muted mb-0">
                    {media.shortDescription}
                  </p>
                </td>
                <td> {getFormattedDateAndTimeFromMillisInSGT(media.createdTime)}</td>
                <td>
                  <div className="avatar-group">
                    {map(media.images, (image, index) =>
                      <div
                        className="avatar-group-item"
                        key={index}
                      >
                        <Link
                          to="#"
                          className="team-member d-inline-block"
                          id="member1"
                        >
                          <img
                            src={image.imageUrl}
                            className="rounded-circle avatar-xs"
                            alt=""
                          />
                          {image.description ? <UncontrolledTooltip
                            placement="top"
                            target="member1"
                          >
                            {image.description}
                          </UncontrolledTooltip> :
                          null
                          }
                        </Link>
                    </div>
                    )}
                  </div>
                </td>
                <td>
                  <Button
                    color="cust-1"
                    className="w-lg me-2"
                    onClick={(e) => {
                      onClickEditMedia(media)
                    }}
                    disabled={inProgress}
                    >
                      EDIT
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      </Slide>
      }
      {isGallery ? (
          <Lightbox
            mainSrc={selectedFiles[photoIndex].preview}
            nextSrc={selectedFiles[(photoIndex + 1) % selectedFiles.length].preview}
            prevSrc={selectedFiles[(photoIndex + selectedFiles.length - 1) % selectedFiles.length].preview}
            enableZoom={true}
            onCloseRequest={() => {
              setisGallery(false)
            }}
            onMovePrevRequest={() => {
              setphotoIndex((photoIndex + selectedFiles.length - 1) % selectedFiles.length)
            }}
            onMoveNextRequest={() => {
              setphotoIndex((photoIndex + 1) % selectedFiles.length)
            }}
            imageCaption={selectedFiles[photoIndex].name}
          />
        ) : null}
    </React.Fragment>
  );
};

AddNewMedia.propTypes = {
};

export default AddNewMedia;
