export const GET_USER_DETAILS = "/get-user-details";
export const FETCH_DETAILS = "/oapp-fetch";
export const INSERT_DETAILS = "/oapp-add";
export const UPDATES_DETAILS = "/oapp-change";
export const DELETE_DETAILS = "/oapp-remove";

export const UPLOAD_PREFIX_FOLDER = "uploads"

export const ADD_MASTER_DATA = "/add-master-data";
export const GET_MASTER_DATA = "/add-master-data";
export const DELETE_ATTACHEMENTS = "/add-master-data";
export const ADD_ATTACHEMENT = "/add-master-data";
export const GET_CONFIG = "/add-master-data";
export const POST_IMAGES = "/add-master-data";

export const POST_FAKE_REGISTER = "/";
export const POST_FAKE_LOGIN = "/";
export const POST_FAKE_PASSWORD_FORGET = "/";
export const POST_EDIT_JWT_PROFILE = "/";
export const POST_EDIT_PROFILE = "/";
export const POST_FAKE_JWT_LOGIN = "/";
export const POST_FAKE_JWT_PASSWORD_FORGET = "/";
export const SOCIAL_LOGIN = "/";
export const GET_PRODUCTS = "/";
export const GET_PRODUCTS_DETAIL = "/";
export const ADD_NEW_EVENT = "/";
export const UPDATE_EVENT = "/";
export const DELETE_EVENT = "/";
export const GET_CATEGORIES = "/";
export const GET_CHATS = "/";
export const GET_GROUPS = "/";
export const GET_CONTACTS = "/";
export const GET_MESSAGES = "/";
export const ADD_MESSAGE = "/";
export const GET_ORDERS = "/";
export const ADD_NEW_ORDER = "/";
export const UPDATE_ORDER = "/";
export const DELETE_ORDER = "/";
export const GET_CART_DATA = "/";
export const GET_CUSTOMERS = "/";
export const ADD_NEW_CUSTOMER = "/";
export const UPDATE_CUSTOMER = "/";
export const DELETE_CUSTOMER = "/";
export const GET_SHOPS = "/";
export const GET_WALLET = "/";
export const GET_CRYPTO_ORDERS = "/";
export const GET_INVOICES = "/";
export const GET_INVOICE_DETAIL = "/";
export const GET_PROJECTS = "/";
export const GET_PROJECT_DETAIL = "/";
export const GET_TASKS = "/";
export const GET_USERS = "/";
export const ADD_NEW_USER = "/";
export const UPDATE_USER = "/";
export const DELETE_USER = "/";
export const ADD_NEW_PROJECT = "/";
export const UPDATE_PROJECT = "/";
export const DELETE_PROJECT = "/";
export const GET_USER_PROFILE = "/";
export const GET_INBOX_MAILS = "/";
export const ADD_NEW_INBOX_MAIL = "/";
export const GET_STARRED_MAILS = "/";
export const DELETE_INBOX_MAIL = "/";
export const GET_IMPORTANT_MAILS = "/";
export const GET_SENT_MAILS = "/";
export const GET_TRASH_MAILS = "/";
export const GET_DRAFT_MAILS = "/";
export const GET_WEEKLY_DATA = "/";
export const GET_YEARLY_DATA = "/";
export const GET_MONTHLY_DATA = "/";
export const TOP_SELLING_DATA = "/";
export const GET_EARNING_DATA = "/";
export const GET_PRODUCT_COMMENTS = "/";
export const ON_LIKNE_COMMENT = "/";
export const ON_ADD_COMMENT = "/";
export const ON_ADD_REPLY = "/";
export const GET_EVENTS = "/";
