import React, { useState, useEffect } from "react";
import { Row, Col, CardBody, Card, Alert, Container, Input, Label, Form, FormFeedback } from "reactstrap";
import { Auth } from "aws-amplify";
// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";
import VerifyAccount from "./VerifyAccount"
// action
import { registerUser, apiError } from "../../store/actions";

//redux
import { useSelector, useDispatch } from "react-redux";

import { Link } from "react-router-dom";

// import images
import profileImg from "../../assets/images/profile-img.png";
import logoImg from "../../assets/images/logo_black-c.png";

const Register = props => {

   //meta title
   document.title="Register | DoctorChamp";

  const dispatch = useDispatch();

  const [registerError, setRegisterError] = useState()
  const [inProgress, setInProgress] = useState(false)
  const [maskedEmail, setMaskedEmail] = useState('')
  const [username, setUserName] = useState('')
  const [registerSuccess, setRegisterSuccess] = useState(false)

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: '',
      username: '',
      password: '',
      confirmpassword: ''
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      username: Yup.string().required("Please Enter Your Username"),
      password: Yup.string().matches('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$' , 'Password must contain 1 uppercase, 1 lowercase, 1 digit, 1 special character and minimum 8 character').required("Please Enter Your Password"),
      confirmpassword: Yup.string().required("Please Enter Same Password").oneOf([Yup.ref('password'), null], 'Passwords must match'),
    }),
    onSubmit: (values) => {
      handleValidSubmit(values);
    }
  });

  const handleValidSubmit = async (values) => {
    
    setInProgress(true)
    setRegisterError()
    //console.log('values::'+(values))
    setUserName(values.email)
    const tempName = values.name
    Auth.signUp({
      username: values.email,
      password: values.confirmpassword,
      attributes: {
        name:values.username,
        email:values.email
      },
    })
    .then((user) => {
      setRegisterSuccess(true)
      setInProgress(false)
      //setEmail("");
      //setPassword("");
      //console.log(user)
      //localStorage.setItem("authUser", JSON.stringify(user))
      setMaskedEmail(user.codeDeliveryDetails.Destination)
      //localStorage.setItem("authUser", JSON.stringify(user))
      // props.history.push("/dashboard")
    })
    .catch((err) => {
      console.log(JSON.stringify(err));
      if(err.code && err.code=='UsernameExistsException'){
        setRegisterError('This email is already registered.')
      }
      else if(err.code && err.code=='NotAuthorizedException'){
        setRegisterError('Registration Failed. Please check your email or password')
      }
      else if(err.code && err.code=='InvalidPasswordException'){
        setRegisterError('Password must contain 1 uppercase, 1 lowercase, 1 digit, 1 special character and minimum 8 character')
      }
      console.log('Error:',JSON.stringify(err));
      setInProgress(false)
    });
    //dispatch(registerUser(values))
  }

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      {registerSuccess ? <VerifyAccount username={username} maskedEmail={maskedEmail}></VerifyAccount> :
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Register</h5>
                        <p>Get your free account.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profileImg} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logoImg}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      {registerError && registerError ? (
                        <Alert color="danger"> 
                          <span className="alert-icon"><i className="bx bx-error-circle me-1 font-size-18 align-middle"></i></span>
                          <span className="alert-text align-middle">{registerError}</span>
                        </Alert>
                      ) : null}

                      <div className="mb-3">
                        <Label className="form-label">Email</Label>
                        <Input
                          id="email"
                          name="email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email ? true : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">Username</Label>
                        <Input
                          name="username"
                          type="text"
                          placeholder="Enter username"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.username || ""}
                          invalid={
                            validation.touched.username && validation.errors.username ? true : false
                          }
                        />
                        {validation.touched.username && validation.errors.username ? (
                          <FormFeedback type="invalid">{validation.errors.username}</FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">Password</Label>
                        <Input
                          name="password"
                          type="password"
                          placeholder="Enter Password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.password || ""}
                          invalid={
                            validation.touched.password && validation.errors.password ? true : false
                          }
                        />
                        {validation.touched.password && validation.errors.password ? (
                          <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">Confirm Password</Label>
                        <Input
                          name="confirmpassword"
                          type="password"
                          placeholder="Confirm Password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.confirmpassword || ""}
                          invalid={
                            validation.touched.confirmpassword && validation.errors.confirmpassword ? true : false
                          }
                        />
                        {validation.touched.confirmpassword && validation.errors.confirmpassword ? (
                          <FormFeedback type="invalid">{validation.errors.confirmpassword}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="mt-4">
                        <button
                          className="btn btn-primary btn-block "
                          type="submit"
                        >
                          Register
                        </button>
                      </div>

                      <div className="mt-4 text-center">
                        <p className="mb-0">
                          By registering you agree to the DC{" "}
                          <Link to="#" className="text-primary">
                            Terms of Use
                          </Link>
                        </p>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Already have an account ?{" "}
                  <Link to="/login" className="font-weight-medium text-primary">
                    {" "}
                    Login
                  </Link>{" "}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      }
    </React.Fragment>
  );
};

export default Register;
