import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  CardTitle,
  Table,
  Row,
} from "reactstrap";
import {fetchDetails} from "helpers/backend_helper"
//redux
import { useSelector, useDispatch } from "react-redux";

const MedalTable = props => {
  const dispatch = useDispatch();

  const [medalTable, setMedalTable] = useState([]);

 
  useEffect(()=>{
    setMedalTable(props.medals)
  },[props.medals])

  return (
    <React.Fragment>
      <Row>
        <Col md="12">
          <Card>
            <CardBody>
              <CardTitle className="mb-3">Medal Table</CardTitle>
                <div className="table-responsive">
                <Table className="table mb-0 table-sm">
                  <tbody>
                    <tr className="text-center custom-table-header">
                      <th>Group :</th>
                      <th>Gold</th>
                      <th>Silver</th>
                      <th>Bronze</th>
                    </tr>
                    {medalTable.map((each,i) => (
                      <tr key={"medal"+i} className="text-center">
                        <td className="text-center">{each.groupName}</td>
                        <td>{each.gold}</td>
                        <td>{each.silver}</td>
                        <td>{each.bronze}</td>
                    </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

MedalTable.propTypes = {
};

export default MedalTable;
