import PropTypes from 'prop-types';
import React, { useEffect } from "react";
import { Amplify, Auth } from 'aws-amplify';
import { Switch, BrowserRouter as Router } from "react-router-dom";
import { connect } from "react-redux";

// Import Routes all
import { authProtectedRoutes, publicRoutes } from "./routes";

// Import all middleware
import Authmiddleware from "./routes/route";

// layouts Format
import VerticalLayout from "./components/VerticalLayout/";
import HorizontalLayout from "./components/HorizontalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";

// Import scss
import "./assets/scss/theme.scss";


const App = props => {

  useEffect(()=>{
    Amplify.configure({
      Auth: {
          // REQUIRED - Amazon Cognito Region
          region: process.env.REACT_APP_REGION,
          userPoolId: process.env.REACT_APP_USER_POOL_ID,
          userPoolWebClientId: process.env.REACT_APP_WEB_CLIENT_ID,
          identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
   
          // OPTIONAL - This is used when autoSignIn is enabled for Auth.signUp
          // 'code' is used for Auth.confirmSignUp, 'link' is used for email link verification
          //signUpVerificationMethod: 'code', // 'code' | 'link' 
  
          // OPTIONAL - Configuration for cookie storage
          // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
          /*
          cookieStorage: {
              domain: '.yourdomain.com',
              path: '/',
              expires: 365,
              sameSite: "strict" | "lax",
              secure: true
          },
          */
          //authenticationFlowType: 'USER_PASSWORD_AUTH',
  
      },
      Storage : {
        bucket : process.env.REACT_APP_BUCKET_NAME,
        region: process.env.REACT_APP_REGION,
        identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
      }
    });
  
  },[])

  function getLayout() {
    let layoutCls = VerticalLayout;
    switch (props.layout.layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout;
        break;
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  }
  

  const Layout = getLayout();
  return (
    <React.Fragment>
      <Router>
        <Switch>
          {publicRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
              isAuthProtected={false}
              exact
            />
          ))}

          {authProtectedRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={Layout}
              component={route.component}
              key={idx}
              isAuthProtected={true}
              exact
            />
          ))}
        </Switch>
      </Router>
    </React.Fragment>
  );
};

App.propTypes = {
  layout: PropTypes.any
};

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  };
};

export default connect(mapStateToProps, null)(App);
