import React, { useEffect, useState } from "react"
import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import {fetchDetails, insertDetails} from "helpers/backend_helper"
import { processPlayerResp } from "helpers/function_helper"

const CustomCreatableSelect = props => {
  const { sType, onSelect, creatable, sKey, isMultiOpt, defaultValue, resetValue, disabled, eventCode, retriggerCounter } = props
  
  const [inProgress, setInProgress] = useState(false);
  const [dropDownOptions, setDropDownOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState();

  useEffect(()=>{
    //console.log('defaultValue:',defaultValue)
    if(defaultValue){
      setSelectedOption(defaultValue)
    }
  },[defaultValue])

  const handleChange=(opt)=>{
    //console.log('handleChange opt:',opt)
    //console.log('selectedOption:',selectedOption)
    //console.log('opt:length:',opt.length)
    setSelectedOption(opt)
    onSelect({'objKey':sKey, 'selectedOption':opt})
  }
  const handleCreateOption = async(opt) => {
    setInProgress(true)

    let inputToSend = {}
    
    if(sType == 'GROUPS'){
      inputToSend = {
        "data": {
          "type": sType,
          "team": {
            "groupName": opt,
            "eventCode": eventCode
          }
        }
      }
    }

    const resp = await insertDetails(inputToSend)
    if(resp && resp.status == 'success'){
      setDropDownOptions(resp.data)
      const selectedOpt = resp.data.filter(each => each.label == opt)
      if(selectedOpt && selectedOpt.length == 1){
        if(isMultiOpt && selectedOption){
          const tempExistingOptions = [...selectedOption]
          tempExistingOptions.push(selectedOpt[0])
          setSelectedOption(tempExistingOptions)
          onSelect({'objKey':sKey, 'selectedOption':tempExistingOptions})
          //onSelect(tempExistingOptions)
        }
        else{
          setSelectedOption(selectedOpt[0])
          //onSelect(selectedOpt[0])
          onSelect({'objKey':sKey, 'selectedOption':selectedOpt[0]})
        }
      }
    }
    setInProgress(false)
  }

  useEffect(()=>{
    loadOptions()
  },[sType, sKey, retriggerCounter])

  const loadOptions = async() => {
    setInProgress(true)
    let inputToSend = {
      "data": {
        "type": sType
      }
    }

    if(sType == 'GROUPS'){
      inputToSend = {
        "data": {
          "type": sType,
          "event": {
            "eventCode": eventCode
          }
        }
      }
    }
    else if(sType == 'PLAYERS'){
      inputToSend = {
        "data": {
          "type": sType,
          "team": {
            "groupId": sKey,
            "eventCode": eventCode
          }
        }
      }
    }

    const resp = await fetchDetails(inputToSend)
    //console.log('resp::',resp)
    if(resp && resp.status == 'success'){
      if(sType == 'PLAYERS'){
        setDropDownOptions(processPlayerResp(resp.data))
      }
      else{
        setDropDownOptions(resp.data)
      }
    }
    setInProgress(false)
  }

  useEffect(()=>{
    if(resetValue){
      setSelectedOption(null)
    }
  },[resetValue])

  return (
    <React.Fragment>
      {creatable ? 
      <CreatableSelect
        value={selectedOption}
        onChange={s => {
          handleChange(s)
        }}
        isLoading={inProgress}
        isDisabled={disabled? true: false}
        options={dropDownOptions}
        isMulti={isMultiOpt? true : false}
        placeholder="Select..."
        classNamePrefix="select2-selection"
        menuPortalTarget={document.body}
        onCreateOption={s => {
          handleCreateOption(s)
        }}
      />
      :
      <Select
        value={selectedOption}
        onChange={s => {
          handleChange(s)
        }}
        isLoading={inProgress}
        isDisabled={disabled? true: false}
        isMulti={isMultiOpt? true : false}
        options={dropDownOptions}
        placeholder="Select..."
        classNamePrefix="select2-selection"
        menuPortalTarget={document.body}
      />
      }
    </React.Fragment>
  )
}

CustomCreatableSelect.propTypes = {
}

export default CustomCreatableSelect
