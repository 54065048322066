import React, { useEffect, useState } from 'react';
import * as dayjs from 'dayjs'
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import utc from 'dayjs/plugin/utc'
import timezone from'dayjs/plugin/timezone'
import Swal from 'sweetalert2'
import { isEqual } from "lodash";
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)
import Storage from "@aws-amplify/storage";
import {UPLOAD_PREFIX_FOLDER} from "./url_helper"
import imageCompression from 'browser-image-compression';

dayjs.extend(utc)
dayjs.extend(timezone)

export function createGroupDropDown(inputArray){
  const ddMap = new Map()
  inputArray.forEach(each => {
    if(ddMap.get(each.gameId)){
      const currentGames = ddMap.get(each.gameId);
      currentGames.push({
        ...each,
        label : each.gameCatName,
        value : each.gameCatId,
      })
      ddMap.set(each.gameId,currentGames);
    }
    else{
      const newGame = []
      newGame.push({
        ...each,
        label : each.gameCatName,
        value : each.gameCatId,
      })
      ddMap.set(each.gameId,newGame);
    }
  })

  const finalOptions = []
  ddMap.forEach(function(value, key) {
    finalOptions.push({
      label: value[0].gameName,
      options : value
    })
  })
  return finalOptions
}

export function showSuccessAlert(message){
    MySwal.fire({
      title: <strong>Success!</strong>,
      html: message,
      icon: 'success'
    })
  }
  export function showFailureAlert(message){
    MySwal.fire({
      title: <strong>Failed!</strong>,
      html: message,
      icon: 'error'
    })
  }
  export function checkWhetherLoggedInUser(userId){
    if (localStorage.getItem("olympUser")) {
        const userJson = localStorage.getItem("olympUser");
        const obj = JSON.parse(userJson);
        if(obj && obj.username && obj.username == userId){
            return true;
        }
    }
    return false;
}

export function checkAccessForURL(url){
    if (localStorage.getItem("olympUser")) {
        const userJson = localStorage.getItem("olympUser");
        const obj = JSON.parse(userJson);
        if(obj && obj.validUrls && obj.validUrls.includes(url)){
            return true;
        }
    }
    return false;
}

export function processPlayerResp(players){
  if(players && players.length > 0){
    players = players.map(each=>{
      let label = each.playerName;
      if(each.playerNo && each.playerNo != ''){
        label = label + ' - ' + each.playerNo
      }
      if(each.groupName && each.groupName != ''){
        label = label + ' [' + each.groupName + ']'
      }
      return {
        ...each,
        label: label,
        value: each.id
      }
    })
    return players
  }
  return []
}
export function findChangesV2(oldInput, newInput, ignoreKeys=[]){
  const changedKeys = []
  const changedKeysAndValues = []
  Object.keys(oldInput)
  .forEach(eachKey =>{
    if(!ignoreKeys.includes(eachKey) && !isEqual(oldInput[eachKey], newInput[eachKey])){
      changedKeysAndValues.push({
        key: eachKey,
        oldValue: oldInput[eachKey],
        newValue: newInput[eachKey]
      })
      changedKeys.push(eachKey)
    }
  })

  Object.keys(newInput)
  .forEach(eachKey =>{
    if(!ignoreKeys.includes(eachKey) && !changedKeys.includes(eachKey) && !isEqual(oldInput[eachKey], newInput[eachKey])){
      changedKeysAndValues.push({
        key: eachKey,
        oldValue: oldInput[eachKey],
        newValue: newInput[eachKey]
      })
      changedKeys.push(eachKey)
    }
  })
  return changedKeysAndValues
}

export function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

export const Offsymbol = ({label}) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2
        }}
      >
        {" "+label}
      </div>
    );
  };
  
export const OnSymbol = ({label}) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2
        }}
      >
        {" "+label}
      </div>
    );
  };

export async function getLoggedInUserDetails(){
    if (localStorage.getItem("olympUser")) {
        const userJson = await localStorage.getItem("olympUser");
        const obj = JSON.parse(userJson);
        return obj
    }
    return undefined
}

export function useKeyPress(targetKey) {
    // State for keeping track of whether key is pressed
    const [keyPressed, setKeyPressed] = useState(false);
    // If pressed key is our target key then set to true
    function downHandler({ key }) {
        console.log('key:',key)
    if (key === targetKey) {
            setKeyPressed(true);
        }
    }
    // If released key is our target key then set to false
    const upHandler = ({ key }) => {
    if (key === targetKey) {
        setKeyPressed(false);
        }
    };
    // Add event listeners
    useEffect(() => {
        window.addEventListener("keydown", downHandler);
        window.addEventListener("keyup", upHandler);
        // Remove event listeners on cleanup
        return () => {
            window.removeEventListener("keydown", downHandler);
            window.removeEventListener("keyup", upHandler);
        };
    }, []); // Empty array ensures that effect is only run on mount and unmount
    return keyPressed;
}

export function isAlphaNumeric(inputtxt) {
    return /^[0-9a-zA-Z]+$/.test(inputtxt);
}

export function isNumeric(inputtxt) {
    return /^[0-9.]+$/.test(inputtxt);
}

export function getFormattedDateAndTime(date, format) {
    const dateFormat = format ? format : "DD MMM Y";
    const response = dayjs(new Date(date)).format(dateFormat);
    return response;
}

export function getFormattedDateAndTimeFromMillis(date, format) {
    const dateFormat = format ? format : "DD MMM Y HH:mm";
    const response = dayjs(date).format(dateFormat);
    return response;
}

export function getFormattedDateAndTimeFromMillisInSGT(date, format) {
    const dateFormat = format ? format : "DD MMM Y HH:mm";
    const response = dayjs(date).tz("Asia/Singapore").format(dateFormat);
    return response;
}

export function checkWhetherExpiredInSGT(dateInMillis) {
  return dayjs().tz("Asia/Singapore").isAfter(dayjs(Number(dateInMillis)));
}

export function showCloseableToast(message, position, toastType) {
    toastr.options = {
        positionClass: "toast-" + position, //top/bottom-left/right/center/full-width, 
        timeOut: 1000,
        closeButton: false,
        debug: false,
        progressBar: true,
        preventDuplicates: false,
        newestOnTop: true,
        showEasing: 'swing',
        hideEasing: 'linear',
        showMethod: 'fadeIn',
        hideMethod: 'fadeOut',
        showDuration: 300,
        hideDuration: 1000,
        title: ""
    }
    if (toastType === "info") toastr.info(message, "Info")
    else if (toastType === "warning") toastr.warning(message, "Warning")
    else if (toastType === "error") toastr.error(message, "Alert")
    else toastr.success(message, "Success")
}

function fallbackCopyTextToClipboard(text) {
    var textArea = document.createElement("textarea");
    textArea.value = text;

    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    var successful = false
    try {
        successful = document.execCommand('copy');
        if (successful) {
            showCloseableToast('Copied Successfully', 'top-center', 'info')
        }
    } catch (err) {
        console.error('Fallback: Oops, unable to copy', err);
    }
    document.body.removeChild(textArea);
}

export function copyTextToClipboard(text) {
    if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(text);
        return
    }
    navigator.clipboard.writeText(text).then(function () {
        showCloseableToast('Copied Successfully', 'top-center', 'success')
    }, function (err) {
        console.error('Async: Could not copy text: ', err);
    });
}

export function secondsToHms(d) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);
    
    var hDisplay = h > 0 ? h + (h == 1 ? " h, " : " h, ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? " m, " : " m, ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " s" : " s") : "";
    return hDisplay + mDisplay + sDisplay; 
}

export function secondsToHm(d) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);
    
    var hDisplay = h > 0 ? h + (m > 0 ? " hrs, " : " hrs") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? " min" : " min") : "";
    //var sDisplay = s > 0 ? s + (s == 1 ? " s" : " s") : "";
    return hDisplay + mDisplay ; 
}

export function getLabelStyle(label){
    return (<span className="px-1 bg-light">{label}</span>)
  }

  export const compressImageFile = async (image) => {
    var options = {
      maxSizeMB: 5,
      maxWidthOrHeight:1920
    }
    try {
      const compressedFile = await imageCompression(image, options);
      return compressedFile
    } catch (error) {
      console.log(error);
    }
  }

  export function SetS3Config(level,bucket){
    if(bucket == undefined || bucket === ''){
      bucket = process.env.REACT_APP_BUCKET_NAME
    }
    Storage.configure({ 
        bucket: bucket,
        level: level,
        region: process.env.REACT_APP_REGION,  
        identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID
    });
  }

  export async function uploadImages(inpFiles){
    
    const promises = [];
    for(let inpFile of inpFiles) {
      //const renamedFile = renameFile(inpFile, new Date().getTime()+'_'+inpFile.name);
      const succ = await compressImageFile(inpFile).then(compressed => {
        const renamedFile = renameFile(compressed, new Date().getTime()+'_'+compressed.name);
        SetS3Config("public");
        console.log('inpFile.type:::',inpFile.type)
        return new Promise((resolve, reject) => {
          Storage.put(UPLOAD_PREFIX_FOLDER+"/"+renamedFile.name, renamedFile, { contentType: inpFile.type })
          .then(result =>{
            console.log('result::',result)
            resolve ({
              status: 'success',
              imageUrl: result.key
            })
          })
          .catch(err => {
            console.log('err',err)
            reject({
                status: 'failure',
                error: err
            })
          });
        })
         
      })
      //console.log('succ::',succ)
      promises.push(succ)
    }
    return promises;
  };

  export function uploadImage(inpFile){
    return new Promise((resolve, reject) => {
      SetS3Config("public");
      
      const renamedFile = renameFile(inpFile, new Date().getTime()+'_'+inpFile.name);
      Storage.put(UPLOAD_PREFIX_FOLDER+"/"+renamedFile.name, renamedFile, { contentType: inpFile.type })
        .then(result => {
          resolve ({
            status: 'success',
            fileName: result.key
          })
        })
        .catch(err => {
          console.log('err',err)
          reject({
              status: 'failure',
              error: err
          })
        });
    });
  };
  
  export function deleteFile(inpFile){
    return new Promise((resolve, reject) => {
      SetS3Config("public");
      Storage.remove(UPLOAD_PREFIX_FOLDER+"/"+inpFile)
      .then(result => {
        resolve ({
          status: 'success'
        })
      })
      .catch(err => {
        console.log('err',err)
        reject({
            status: 'failure',
            error: err
        })
      });
    });
  }
  
  export const renameFile = (bits, name, options) => {
    try {
      // If this fails, we go for Blob
      return new File([bits], name, options);
    } catch (e) {
      // If we're here a new File could not be constructed
      var myBlob = new Blob([bits], options || {});
      myBlob.lastModified = new Date();
      myBlob.name = name;
      return myBlob;
    }
  };
  
  export function downloadBlob(blob, filename) {
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename || 'download';
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener('click', clickHandler);
      }, 150);
    };
    a.addEventListener('click', clickHandler, false);
    a.click();
    return a;
  }
  
  export async function downloadFile(fileKey) {
    const result = await Storage.get(fileKey, { download: true });
    downloadBlob(result.Body, fileKey);
  }
  
  export async function getSignedUrl(inpFile) {
    const url = await Storage.get(inpFile)
    return url;
    /*
    return new Promise((resolve, reject) => {
      Storage.get(inpFile)
      .then(result => {
        resolve(result)
      })
      .catch(err => {
        console.log('err',err)
        reject(null)
      });
    });*/
  }
  
  export function getOnlyFileName(inpFileName){
    if(inpFileName == null || inpFileName == "" || inpFileName.indexOf(UPLOAD_PREFIX_FOLDER) == -1){
      return inpFileName;
    }
    else {
      return inpFileName.substring(inpFileName.indexOf(UPLOAD_PREFIX_FOLDER)+UPLOAD_PREFIX_FOLDER.length+1)
    }
  }