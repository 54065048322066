import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { differenceBy } from "lodash";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap";
import {fetchDetails, insertDetails, updateDetails} from "helpers/backend_helper"
//redux
import { useSelector, useDispatch } from "react-redux";
import Slide from "react-reveal/Slide";
import Select from "react-select";
import { changePreloader } from "../../store/actions";
import { showSuccessAlert, showFailureAlert, createGroupDropDown, showCloseableToast } from "helpers/function_helper"

const AddNewGame = props => {
  const dispatch = useDispatch();

  const [inProgress, setInProgress] = useState(false);
  const [selectedEventOption, setSelectedEventOption] = useState();
  const [allEventGames, setAllEventGames] = useState();

  useEffect(()=>{
    getEventGames()
  },[])

  const getEventGames=async()=>{
    let inputToSend = {
      "data": {
        "type": 'ALL_EVENT_GAMES',
        "event": {
          "eventCode": "",
        }
      }
    }
    const resp = await fetchDetails(inputToSend)
    if(resp && resp.status=='success'){
      //setAllEventGames(resp.data)
      if(resp.data){
        let nonExistingGames = resp.data
        if(props.existingGames && props.existingGames.length > 0){
          nonExistingGames = differenceBy(resp.data, props.existingGames, 'gameCatId')
        }
        const finalDD = createGroupDropDown(nonExistingGames)
        setAllEventGames(finalDD)
      }
    }
  }

  const handleEventChange=(option)=>{
    setSelectedEventOption(option)
  }

  const addNewGameToEvent=async()=>{
    setInProgress(true)
    dispatch(changePreloader(true))
    let inputToSend = {
      "data": {
        "type": 'NEW_GAME',
        "game":{
          eventCode: props.eventCode,
          gameCatId : selectedEventOption.gameCatId
        }
      }
    }
    const resp = await insertDetails(inputToSend)
    //console.log('resp:',resp)
    if(resp && resp.status == 'success'){
      props.onConfirmAdd(resp.data.id)
      //showSuccessAlert('Added Successfully!')
      showCloseableToast('Added Successfully', 'top-right','success')
    }
    else{
      if(resp.error && resp.error.msg){
        showFailureAlert(resp.error.msg)
      }
      else{
        showFailureAlert('Unknown failure')
      }
    }

    setInProgress(false)
    dispatch(changePreloader(false))
  }

  const cancelAddGame=()=>{
    props.onConfirmAdd()
  }

  return (
    <React.Fragment>
      <Slide duration={300} right opposite>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <Row>
                <Col xs="12">
                  <div className=" d-flex align-items-center justify-content-between">
                    <span className="page-heading">Add Game</span>
                  </div>
                </Col>
              </Row>
              <Row className="mt-3 align-items-center">
                <Col xs={12} md={12}>
                  <Row>
                    <Col xs={12} md={4} className="text-sm-start text-md-end">
                      <Label
                        htmlFor="name"
                        className="col-form-label"
                      >
                        Select Game To Add:
                      </Label>
                    </Col>
                    <Col xs={12} md={4} className="">
                      <Select
                        value={selectedEventOption}
                        onChange={s => {
                          handleEventChange(s)
                        }}
                        isLoading={inProgress}
                        isDisabled={false}
                        isMulti={false}
                        options={allEventGames}
                        placeholder="Select..."
                        classNamePrefix="select2-selection"
                        menuPortalTarget={document.body}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              
              <Row className="mt-4 justify-content-center">
                <Col className="text-center">
                  <Button
                    color="secondary"
                    className="w-lg me-2"
                    onClick={(e) => {
                      cancelAddGame()
                    }}
                    disabled={inProgress}
                    >
                      Close
                  </Button>
                  <Button
                    color="cust-1"
                    className="w-lg "
                    onClick={(e) => {
                      addNewGameToEvent()
                    }}
                    disabled={inProgress}
                    >
                      ADD
                      {inProgress && <i className="bx bx-loader bx-spin font-size-16 align-middle ms-2 me-2"></i>}
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      </Slide>
    </React.Fragment>
  );
};

AddNewGame.propTypes = {
};

export default AddNewGame;
