import {
  GET_MY_EVENTS,
  GET_MY_EVENTS_SUCCESS
} from "./actionTypes"

const initialState = {
  loading: false,
  events: [],
}

const Olympics = (state = initialState, action) => {
  switch (action.type) {
    case GET_MY_EVENTS:
      state = {
        ...state,
        loading: true
      }
      break
    case GET_MY_EVENTS_SUCCESS:
      state = {
        ...state,
        loading: false,
        events: action.payload,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default Olympics
