import { del, get, post, put, postImage, postArray } from "./api_helper"
import * as url from "./url_helper"

export const fetchDetails = async (data) => {
  return post(url.FETCH_DETAILS, data)
}

export const insertDetails = async (data) => {
  return post(url.INSERT_DETAILS, data)
}

export const updateDetails = async (data) => {
  return post(url.UPDATES_DETAILS, data)
}

export const deleteDetails = async (data) => {
  return post(url.DELETE_DETAILS, data)
}

export const addMasterData = async (data) => {
  return post(url.ADD_MASTER_DATA, data)
}
export const fetchMasterData = async (data) => {
  return post(url.GET_MASTER_DATA, data)
}

export const deleteAttachments = async (data) => {
  return post(url.DELETE_ATTACHEMENTS, data)
}
export const addAttachement = async (data) => {
  return post(url.ADD_ATTACHEMENT, data)
}

export const getUserDetails = async (data) => {
  return post(url.GET_USER_DETAILS, data)
}
// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("user")
  if (user) return JSON.parse(user)
  return null
}

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null
}

export const getConfigs = async (data) => {
  return post(url.GET_CONFIG, data)
}

const postImages = async (data) => {
  const resp = await postImage(url.POST_IMAGES, data)
  .then(response => response)
  return resp;
  //return postImage(url.POST_IMAGES, data)
}

export {
  getLoggedInUser,
  isUserAuthenticated,
  postImages
}
