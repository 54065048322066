import React, { useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import { Link } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  UncontrolledTooltip,
  Input,
  Label,
  Row,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
} from "reactstrap";
//redux
import { useSelector, useDispatch } from "react-redux";
import { changePreloader } from "../../store/actions";
import TableContainer from "../../components/Common/TableContainer";
import { Teams , DateAndTime, Value, StartDate, Action} from "./TableCols";

const GameMatches = props => {

  const onClickRow=()=>{

  }

  const handleEditClick=(data)=>{
    props.handleEditClick(data)
  }
  const handleScoreClick=(data)=>{
    props.handleScoreClick(data)
  }
  const handleDeleteClick=(data)=>{
    props.handleDeleteClick(data)
  }
  const handleScoreEditClick=(data)=>{
    props.handleScoreEditClick(data)
  }
  const handleMarkAsCompleteClick=(data)=>{
    props.handleMarkAsCompleteClick(data)
  }
  
  const columns = useMemo(
    () => [
      {
        Header: "Match Code",
        accessor: "matchCode",
        //filterable: true,
        disableFilters: true,
        Cell: cellProps => {
          return <Value {...cellProps} />;
        },
      },
      {
        Header: "Round",
        accessor: "roundName",
        //filterable: true,
        disableFilters: true,
        Cell: cellProps => {
          return <Value {...cellProps} />;
        },
      },
      {
        Header: "Time",
        accessor: "matchTimeGMT",
        //filterable: true,
        disableFilters: true,
        Cell: cellProps => {
          return <DateAndTime {...cellProps} />;
        },
      },
      {
        Header: "Status",
        accessor: "gameStatus",
        //filterable: true,
        disableFilters: true,
        Cell: cellProps => {
          return <Value {...cellProps} />;
        },
      },
      {
        Header: "Teams",
        accessor: "teams",
        //filterable: true,
        disableFilters: true,
        Cell: cellProps => {
          return <Teams {...cellProps} />;
        },
      },
      {
        Header: "Action",
        accessor: "status",
        //filterable: true,
        disableFilters: true,
        Cell: cellProps => {
          return (
            <UncontrolledDropdown className="ms-auto">
            <DropdownToggle
              className="text-muted font-size-14"
              color="white"
            >
              <i className="mdi mdi-dots-horizontal"></i>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end">
              <Link 
                className="dropdown-item" 
                to="#" 
                onClick={() => {
                  const data = cellProps.row.original;
                  handleEditClick(data);
                }}>
                <i className="mdi mdi-pencil font-size-18 text-success"/> Edit
              </Link>
              <Link 
                className="dropdown-item" 
                to="#"
                onClick={() => {
                  const data = cellProps.row.original;
                  handleDeleteClick(data);
                }}>
                <i className="mdi mdi-delete font-size-18 text-danger" /> Delete
              </Link>
              <div className="dropdown-divider"></div>
              {cellProps.row.original.teams && cellProps.row.original.teams[0] && cellProps.row.original.teams[0].score? 
              <Link 
                className="dropdown-item" 
                to="#"
                onClick={() => {
                  const data = cellProps.row.original;
                  handleScoreEditClick(data);
                }}>
                Update Score
              </Link> 
              :
              <Link 
                className="dropdown-item" 
                to="#"
                onClick={() => {
                  const data = cellProps.row.original;
                  handleScoreClick(data);
                }}>
                Add Score
              </Link>
              }
              <div className="dropdown-divider"></div>
              <Link 
                className="dropdown-item" 
                to="#"
                onClick={() => {
                  const data = cellProps.row.original;
                  handleMarkAsCompleteClick(data);
                }}>
                Mark as Complete
              </Link>
            </DropdownMenu>
          </UncontrolledDropdown>
          )
        }
      }
    ],
    []
  );

  return (
    <React.Fragment>
      <Card style={{ minHeight:"400px" }}>
        <CardBody>
          <TableContainer
            columns={columns}
            data={props.matches}
            isGlobalFilter={true}
            isAddOptions={false}
            customPageSize={10}
            clickable={true}
            onClickRow={onClickRow}
          />
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

GameMatches.propTypes = {
};

export default GameMatches;
