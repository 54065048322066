import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { differenceBy, isNumber } from "lodash";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Badge,
  Row,
} from "reactstrap";
import {fetchDetails, insertDetails, updateDetails} from "helpers/backend_helper"
//redux
import { useSelector, useDispatch } from "react-redux";
import { changePreloader } from "../../store/actions";
import { showSuccessAlert, showFailureAlert, findChangesV2, showCloseableToast } from "helpers/function_helper"


const UpdateScore = props => {
  const dispatch = useDispatch();
 
  const [inProgress, setInProgress] = useState(false);
  const [matchDetailsLocal, setMatchDetailsLocal] = useState();
  const [isEdit, setIsEdit] = useState(false);

  const [teamRows, setTeamRows] = useState([])

  useEffect(()=>{
    setMatchDetailsLocal(props.matchDetails)
    //console.log('props.matchDetails:::',props.matchDetails)
    if(props.matchDetails.teams){
      updateTeamRows(props.matchDetails.teams)
    }
  },[props.matchDetails])

  function updateTeamRows(teams){
    const updatedTeamRows = []
    let index = 1;
    teams.forEach(each=>{
      if(each.score){
        setIsEdit(true)
      }
      updatedTeamRows.push({
        idx: index++,
        ...each,
        scoreId: each.score?.scoreId ?  each.score?.scoreId : '',
        score1: each.score?.score1 ?  each.score?.score1 : '',
        score2: each.score?.score2 ?  each.score?.score2 : '',
        score3: each.score?.score3 ?  each.score?.score3 : '',
        score4: each.score?.score4 ?  each.score?.score4 : '',
        score5: each.score?.score5 ?  each.score?.score5 : '',
      })
    })
    setTeamRows(updatedTeamRows)
  }

  const mandatoryFields = ['score1']
  const [validation, setValidation] = useState({
    score1: null,
  })

  function handleInputTextChange(id, key, value) {
    var modifiedRows = [...teamRows]
      modifiedRows = modifiedRows.map(eachRow => {
      if(eachRow["idx"] == id){
        return {
          ...eachRow,
          [key]: value,
        }
      }
      else {
        return eachRow
      }
    })
    setTeamRows(modifiedRows)
  }

  function validateTextInput(key,value){
    if(value && value != ""){
      setValidation({
        ...validation,
        [key]:true
      })
    }
    else{
      setValidation({
        ...validation,
        [key]:true
      })
    }
  }

  function closePopUp(){
    props.onClosePopUp()
  }

  function isNumber(num){
    if(Number(num) || num == 0){
      return true
    }
    return false
  }

  function validateFormSubmit(){
    let formValid = true
    const modifiedView = { ...validation }

    let currentRows = [...teamRows]
    mandatoryFields.forEach(eachItem =>{
      currentRows.forEach(eachRow=>{
        if(eachRow[eachItem] && String(eachRow[eachItem]).trim() != '' ){
          modifiedView[eachItem+'-'+eachRow.idx] = true
        }
        else{
          modifiedView[eachItem+'-'+eachRow.idx] = false
          formValid = false
        }
      })
    })
    setValidation(modifiedView)
    return formValid
  }

  const addScoreToMatch=async()=>{
    const isValid = validateFormSubmit()
    if(!isValid){
      return
    }
    setInProgress(true)
    dispatch(changePreloader(true))
    console.log('isEdit:',isEdit)
    if(isEdit){
      let inputToSend = {
        "data": {
          "type": 'UPDATE_SCORE',
          "match":{
            matchId: props.matchDetails.matchId,
            scores: teamRows
          }
        }
      }
      const resp = await updateDetails(inputToSend)
      //console.log('resp:',resp)
      if(resp && resp.status == 'success'){
        props.onConfirmUpdate(resp.data.id)
        //showSuccessAlert('Added Successfully!')
        showCloseableToast('Updated Successfully', 'top-right','success')
      }
      else{
        if(resp.error && resp.error.msg){
          showFailureAlert(resp.error.msg)
        }
        else{
          showFailureAlert('Unknown failure')
        }
      }
    }
    else{
      let inputToSend = {
        "data": {
          "type": 'NEW_SCORE',
          "match":{
            matchId: props.matchDetails.matchId,
            scores: teamRows
          }
        }
      }
      const resp = await insertDetails(inputToSend)
      //console.log('resp:',resp)
      if(resp && resp.status == 'success'){
        props.onConfirmAddScore(resp.data.id)
        //showSuccessAlert('Added Successfully!')
        showCloseableToast('Added Successfully', 'top-right','success')
      }
      else{
        if(resp.error && resp.error.msg){
          showFailureAlert(resp.error.msg)
        }
        else{
          showFailureAlert('Unknown failure')
        }
      }
    }

    setInProgress(false)
    dispatch(changePreloader(false))
  }

  return (
    <React.Fragment>
      <div className="">
        <Container fluid={true}>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row className="text-center mb-2">
                    <Col>
                      <span className="page-heading">Score</span>
                    </Col>
                  </Row>
                  <Row className="mt-3 align-items-center">
                    <Col xs={12} md={12}>
                      <Row>
                        <div className="inner-repeater mb-1 mt-3">
                          <div className="table-responsive">
                            <table style={{width:"100%"}} className="0">
                              <tbody>
                                <tr>
                                  <td>
                                    <Row className="justify-content-center">
                                      <Col xs="12" md="10" className="text-center">
                                        <Row className="no-gutters custom-table-header">
                                          <Col className="custom-table-cell" xs="1">#</Col>
                                          <Col className="custom-table-cell" xs="6">Team</Col>
                                          <Col className="custom-table-cell" xs="1">Set 1</Col>
                                          <Col className="custom-table-cell" xs="1">Set 2</Col>
                                          <Col className="custom-table-cell" xs="1">Set 3</Col>
                                          <Col className="custom-table-cell" xs="1">Set 4</Col>
                                          <Col className="custom-table-cell" xs="1">Set 5</Col>
                                        </Row>
                                      </Col>
                                    </Row>
                                  </td>
                                </tr>
                                {(teamRows || []).map((formRow, key) =>(
                                  <tr key={"teamR"+key}>
                                    <td>
                                      <Row className="justify-content-center">
                                        <Col xs="12" md="10" className="text-center">
                                          <Row className="no-gutters">
                                            <Col xs="1">
                                              <Input 
                                                id={"team-id-"+key}
                                                type="text" 
                                                className="nopadd text-center form-control" 
                                                disabled 
                                                value={key+1}
                                              />
                                            </Col>
                                            <Col xs="6">
                                              <div className="nopadd text-center form-control">
                                                {formRow.teamName}
                                                <Badge
                                                  className={"ms-2 font-size-12 badge-soft-info"}
                                                >
                                                  {formRow.groupName}
                                                </Badge>
                                              </div>
                                            </Col>
                                            <Col xs="1">
                                              <Input 
                                                id={"team-score-"+formRow.idx}
                                                type="text" 
                                                maxLength={3}
                                                className="nopadd text-center form-control" 
                                                onChange={e=>{
                                                  console.log('e.target.value:',e.target.value)
                                                  if(isNumber(e.target.value)){
                                                    handleInputTextChange(formRow.idx, 'score1', e.target.value)
                                                    validateTextInput("score1-"+formRow.idx, e.target.value)
                                                  }
                                                }} 
                                                value={teamRows.filter(x=>x["idx"]==formRow.idx)[0].score1}
                                              />
                                              {validation["score1-"+formRow.idx] == false &&
                                                <div className="custom-error">{"Please enter the score."}</div>
                                              }
                                            </Col>
                                            <Col xs="1">
                                              <Input 
                                                id={"team-score-"+formRow.idx}
                                                type="text" 
                                                maxLength={3}
                                                className="nopadd text-center form-control" 
                                                onChange={e=>{
                                                  if(isNumber(e.target.value)){
                                                    handleInputTextChange(formRow.idx, 'score2', e.target.value)
                                                    validateTextInput("score2-"+formRow.idx, e.target.value)
                                                  }
                                                }} 
                                                value={teamRows.filter(x=>x["idx"]==formRow.idx)[0].score2}
                                              />
                                              {validation["score2-"+formRow.idx] == false &&
                                                <div className="custom-error">{"Please enter the score."}</div>
                                              }
                                            </Col>
                                            <Col xs="1">
                                              <Input 
                                                id={"team-score-"+formRow.idx}
                                                type="text" 
                                                maxLength={3}
                                                className="nopadd text-center form-control" 
                                                onChange={e=>{
                                                  if(isNumber(e.target.value)){
                                                    handleInputTextChange(formRow.idx, 'score3', e.target.value)
                                                    validateTextInput("score3-"+formRow.idx, e.target.value)
                                                  }
                                                }} 
                                                value={teamRows.filter(x=>x["idx"]==formRow.idx)[0].score3}
                                              />
                                              {validation["score3-"+formRow.idx] == false &&
                                                <div className="custom-error">{"Please enter the score."}</div>
                                              }
                                            </Col>
                                            <Col xs="1">
                                              <Input 
                                                id={"team-score-"+formRow.idx}
                                                type="text" 
                                                maxLength={3}
                                                className="nopadd text-center form-control" 
                                                onChange={e=>{
                                                  if(isNumber(e.target.value)){
                                                    handleInputTextChange(formRow.idx, 'score4', e.target.value)
                                                    validateTextInput("score4-"+formRow.idx, e.target.value)
                                                  }
                                                }} 
                                                value={teamRows.filter(x=>x["idx"]==formRow.idx)[0].score4}
                                              />
                                              {validation["score4-"+formRow.idx] == false &&
                                                <div className="custom-error">{"Please enter the score."}</div>
                                              }
                                            </Col>
                                            <Col xs="1">
                                              <Input 
                                                id={"team-score-"+formRow.idx}
                                                type="text" 
                                                maxLength={3}
                                                className="nopadd text-center form-control" 
                                                onChange={e=>{
                                                  if(isNumber(e.target.value)){
                                                    handleInputTextChange(formRow.idx, 'score5', e.target.value)
                                                    validateTextInput("score5-"+formRow.idx, e.target.value)
                                                  }
                                                }} 
                                                value={teamRows.filter(x=>x["idx"]==formRow.idx)[0].score5}
                                              />
                                              {validation["score5-"+formRow.idx] == false &&
                                                <div className="custom-error">{"Please enter the score."}</div>
                                              }
                                            </Col>

                                          </Row>
                                        </Col>
                                      </Row>
                                    </td>

                                  </tr>
                                  ))}
                                
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </Row>
                    </Col>
                  </Row>
                  
                  
                  <Row className="mt-4 justify-content-center">
                    <Col className="text-center">
                      <Button
                        color="secondary"
                        className="w-lg me-2"
                        onClick={(e) => {
                          closePopUp()
                        }}
                        disabled={inProgress}
                        >
                           Close
                          {inProgress && <i className="bx bx-loader bx-spin font-size-16 align-middle ms-2 me-2"></i>}
                      </Button>
                      <Button
                        color="cust-1"
                        className="w-lg "
                        onClick={(e) => {
                          addScoreToMatch()
                        }}
                        disabled={inProgress}
                        >
                          Save
                          {inProgress && <i className="bx bx-loader bx-spin font-size-16 align-middle ms-2 me-2"></i>}
                      </Button>
                    </Col>
                  </Row>

                </CardBody>
              </Card>
            </Col>
          </Row>
          
        </Container>
      </div>
    </React.Fragment>
  );
};

UpdateScore.propTypes = {
};

export default UpdateScore;
