import React, { useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import { Link } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  UncontrolledTooltip,
  Input,
  Label,
  Row,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
} from "reactstrap";
//redux
import { useSelector, useDispatch } from "react-redux";
import { changePreloader } from "../../store/actions";
import TableContainer from "../../components/Common/TableContainer";
import { Teams , DateAndTime, Value, StartDate, Action} from "./TableCols";
import {fetchDetails, insertDetails, updateDetails} from "helpers/backend_helper"

const GameTeams = props => {

  const [inProgress, setInProgress] = useState(false);
  const [allTeams, setAllTeams] = useState([]);

  useEffect(()=>{
    getRegisteredTeams()
  },[props.gameCatId,props.gameTeamsTriggerCounter])

  const getRegisteredTeams=async()=>{
    let inputToSend = {
      "data": {
        "type": 'GAME_TEAMS',
        "game": {
          "gameCatId": props.gameCatId,
          "eventCode": props.eventCode,
        }
      }
    }
    const resp = await fetchDetails(inputToSend)
    if(resp && resp.status=='success'){
      if(resp.data){
        //console.log('resp.data:',resp.data)
        setAllTeams(resp.data)
        //prepareTeamDD(resp.data)
        //setAllRounds(resp.data)
      }
    }
  }

  const onClickRow=()=>{

  }

  const handleEditClick=(data)=>{
    props.handleEditClick(data)
  }
  
  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        //filterable: true,
        disableFilters: true,
        Cell: cellProps => {
          return <Value {...cellProps} />;
        },
      },
      {
        Header: "Name",
        accessor: "team_name",
        //filterable: true,
        disableFilters: true,
        Cell: cellProps => {
          return <Value {...cellProps} />;
        },
      },
      {
        Header: "Group",
        accessor: "group_name",
        //filterable: true,
        disableFilters: true,
        Cell: cellProps => {
          return <Value {...cellProps} />;
        },
      },
      {
        Header: "Action",
        accessor: "status",
        //filterable: true,
        disableFilters: true,
        Cell: cellProps => {
          return (
            <UncontrolledDropdown className="ms-auto">
            <DropdownToggle
              className="text-muted font-size-14 no-y-padding"
              color="white"
            >
              <i className="mdi mdi-dots-horizontal"></i>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end">
              <Link 
                className="dropdown-item" 
                to="#" 
                onClick={() => {
                  const data = cellProps.row.original;
                  handleEditClick(data);
                }}>
                <i className="mdi mdi-pencil font-size-18 text-success"/> Edit
              </Link>
              {/*<Link 
                className="dropdown-item" 
                to="#"
                onClick={() => {
                  const data = cellProps.row.original;
                  handleDeleteClick(data);
                }}>
                <i className="mdi mdi-delete font-size-18 text-danger" /> Delete
              </Link>
              */}
              
            </DropdownMenu>
          </UncontrolledDropdown>
          )
        }
      }
    ],
    []
  );

  return (
    <React.Fragment>
      <Card style={{ minHeight:"400px" }}>
        <CardBody>
          <TableContainer
            className={"table-sm"}
            columns={columns}
            data={allTeams}
            isGlobalFilter={true}
            isAddOptions={false}
            customPageSize={10}
            clickable={true}
            onClickRow={onClickRow}
          />
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

GameTeams.propTypes = {
};

export default GameTeams;
