import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { differenceBy, isNumber, orderBy } from "lodash";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Badge,
  Row,
} from "reactstrap";
import {fetchDetails, insertDetails, updateDetails} from "helpers/backend_helper"
//redux
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import { changePreloader } from "../../store/actions";
import { showSuccessAlert, showFailureAlert, findChangesV2, showCloseableToast } from "helpers/function_helper"


const UpdateCompletion = props => {
  const dispatch = useDispatch();
 
  const [inProgress, setInProgress] = useState(false);
  const [matchDetailsLocal, setMatchDetailsLocal] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [numberOfSets, setNumberOfSets] = useState(1);
  const [teamRows, setTeamRows] = useState([])
  const [isFinalRound, setIsFinalRound] = useState(false);
  const [isLosersFinal, setIsLosersFinal] = useState(false);

  const medalDD = [{label:'Gold',value:1},{label:'Silver',value:2},{label:'Bronze',value:3},{label:'None',value:0}]

  useEffect(()=>{
    setMatchDetailsLocal(props.matchDetails)
    console.log('props.matchDetails:::',props.matchDetails)
    if(props.matchDetails.roundName && props.matchDetails.roundName == 'Final'){
      setIsFinalRound(true)
    }
    else if(props.matchDetails.roundName && props.matchDetails.roundName == 'Losers Final'){
      setIsLosersFinal(true)
    }
    if(props.matchDetails.teams){
      updateTeamRows(props.matchDetails.teams, props.matchDetails.gameStatus)
      //getNumberOfSets(props.matchDetails.teams)
    }
  },[props.matchDetails])

  function getNumberOfSets(teams){
    let numberOfSets = 1;
    teams.forEach(each=>{
      if(each.score && each.score.score5 && each.score.score5 != ''){
        numberOfSets = 5;
      }
      else if(each.score && each.score.score4 && each.score.score4 != '' && numberOfSets < 4){
        numberOfSets = 4;
      }
      else if(each.score && each.score.score3 && each.score.score3 != '' && numberOfSets < 3){
        numberOfSets = 3;
      }
      else if(each.score && each.score.score2 && each.score.score2 != '' && numberOfSets < 2){
        numberOfSets = 2;
      }
    })
    //console.log('numberOfSets::',numberOfSets)
    setNumberOfSets(numberOfSets)
    return numberOfSets
  }

  function getSelectedMedal(medal){
    const foundValue = medalDD.filter(each => each.value == medal)
    if(foundValue && foundValue.length == 1){
      return foundValue[0]
    }
  }

  function handleMedalChange(idx, value){

  }

  function updateTeamRows(teams, gameStatus){
    const updatedTeamRows = []
    const setCount = getNumberOfSets(teams)
    let objToSort = 'score1'
    if(setCount == 2){
      objToSort = 'score2'
    }
    else if(setCount == 3){
      objToSort = 'score3'
    }
    else if(setCount == 4){
      objToSort = 'score4'
    }
    else if(setCount == 5){
      objToSort = 'score5'
    }

    let index = 1;
    teams.forEach(each=>{
      updatedTeamRows.push({
        idx: index++,
        ...each,
        winner: each.winner ? each.winner : 0,
        selected: each.selected ? each.selected : 0,
        scoreId: each.score?.scoreId ?  each.score?.scoreId : '',
        score1: each.score?.score1 ?  each.score?.score1 : '',
        score2: each.score?.score2 ?  each.score?.score2 : '',
        score3: each.score?.score3 ?  each.score?.score3 : '',
        score4: each.score?.score4 ?  each.score?.score4 : '',
        score5: each.score?.score5 ?  each.score?.score5 : '',
      })
    })
    const sortedTeams = orderBy(updatedTeamRows,'score.'+objToSort,'desc')
    //console.log('sortedTeams:',sortedTeams)
    if(sortedTeams[0] && gameStatus != 'Completed'){ //Completed  should have selected by default
      sortedTeams[0].winner=1
      sortedTeams[0].selected=1
    }

    if(props.matchDetails.roundName && props.matchDetails.roundName == 'Final' && sortedTeams[0] && gameStatus != 'Completed'){
      sortedTeams[0].medal=1
      sortedTeams[0].selected=0
    }
    if(props.matchDetails.roundName && props.matchDetails.roundName == 'Final' && sortedTeams[1] && gameStatus != 'Completed'){
      sortedTeams[1].medal=2
      sortedTeams[1].selected=0
    }
    if(props.matchDetails.roundName && props.matchDetails.roundName == 'Final' && sortedTeams[2] && gameStatus != 'Completed'){
      sortedTeams[2].medal=3
      sortedTeams[2].selected=0
    }
    else if(props.matchDetails.roundName && props.matchDetails.roundName == 'Losers Final' && sortedTeams[0] && gameStatus != 'Completed'){
      sortedTeams[0].medal=3
      sortedTeams[0].selected=0
    }

    console.log('sortedTeams:',sortedTeams)
    setTeamRows(sortedTeams)
  }

  const mandatoryFields = ['score1']
  const [validation, setValidation] = useState({
    score1: null,
  })

  function handleInputTextChange(id, key, value) {
    console.log('teamRows:',teamRows)
    console.log('id:',id,':key:',key,':value:',value)
    var modifiedRows = [...teamRows]

    if('winner'==key){
      modifiedRows = modifiedRows.map(eachRow => {
        return {
          ...eachRow,
          [key]: 0,
        }
      })
    }

    modifiedRows = modifiedRows.map(eachRow => {
      if(eachRow["idx"] == id){
        return {
          ...eachRow,
          [key]: value,
        }
      }
      else {
        return eachRow
      }
    })
    setTeamRows(modifiedRows)
  }

  function closePopUp(){
    props.onClosePopUp()
  }

  function validateFormSubmit(){
    let formValid = true
    const modifiedView = { ...validation }

    let currentRows = [...teamRows]
    mandatoryFields.forEach(eachItem =>{
      currentRows.forEach(eachRow=>{
        if(eachRow[eachItem] && String(eachRow[eachItem]).trim() != '' ){
          modifiedView[eachItem+'-'+eachRow.idx] = true
        }
        else{
          modifiedView[eachItem+'-'+eachRow.idx] = false
          formValid = false
        }
      })
    })
    setValidation(modifiedView)
    return formValid
  }

  const updateWinnerAndSelected=async()=>{
    const isValid = validateFormSubmit()
    console.log('isValid:',isValid)
    if(!isValid){
      return
    }
    setInProgress(true)
    dispatch(changePreloader(true))
    
    let inputToSend = {
      "data": {
        "type": 'UPDATE_RESULT',
        "match":{
          matchId: props.matchDetails.matchId,
          scores: teamRows
        }
      }
    }
    const resp = await updateDetails(inputToSend)
    //console.log('resp:',resp)
    if(resp && resp.status == 'success'){
      props.onConfirmUpdateResult(resp.data.id)
      //showSuccessAlert('Added Successfully!')
      showCloseableToast('Updated Successfully', 'top-right','success')
    }
    else{
      if(resp.error && resp.error.msg){
        showFailureAlert(resp.error.msg)
      }
      else{
        showFailureAlert('Unknown failure')
      }
    }

    setInProgress(false)
    dispatch(changePreloader(false))
  }

  return (
    <React.Fragment>
      <div className="">
        <Container fluid={true}>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row className="text-center mb-2">
                    <Col>
                      <span className="page-heading">Score - {props.matchDetails?.roundName}</span>
                    </Col>
                  </Row>
                  <Row className="mt-3 align-items-center">
                    <Col xs={12} md={12}>
                      <Row>
                        <div className="inner-repeater mb-1 mt-3">
                          <div className="table-responsive">
                            <table style={{width:"100%"}} className="0">
                              <tbody>
                                <tr>
                                  <td>
                                    <Row className="justify-content-center">
                                      <Col xs="12" md="10" className="text-center">
                                        <Row className="no-gutters ">
                                          <Col className="custom-table-cell custom-table-header" xs="1">#</Col>
                                          <Col className="custom-table-cell custom-table-header" xs={5}>Team</Col>
                                          <Col className="custom-table-cell custom-table-header" xs={"1"}>Set 1</Col>
                                          {numberOfSets > 1 && <Col className="custom-table-cell custom-table-header" xs="1">Set 2</Col>}
                                          {numberOfSets > 2 && <Col className="custom-table-cell custom-table-header" xs="1">Set 3</Col>}
                                          {numberOfSets > 3 && <Col className="custom-table-cell custom-table-header" xs="1">Set 4</Col>}
                                          {numberOfSets > 4 && <Col className="custom-table-cell custom-table-header" xs="1">Set 5</Col>}
                                          {/*<Col className="custom-table-cell" xs={"1"}>Winner</Col>*/}
                                          {(!isFinalRound && !isLosersFinal) ? 
                                          <Col className="custom-table-cell custom-table-header" xs={"1"}>Selected</Col> : 
                                          <Col className="custom-table-cell custom-table-header" xs={"2"}>Medal</Col> }
                                        </Row>
                                      </Col>
                                    </Row>
                                  </td>
                                </tr>
                                {(teamRows || []).map((formRow, key) =>(
                                  <tr key={"teamR"+key}>
                                    <td>
                                      <Row className="justify-content-center">
                                        <Col xs="12" md="10" className="text-center">
                                          <Row className="no-gutters">
                                            <Col xs="1">
                                              <Input 
                                                id={"team-id-"+key}
                                                type="text" 
                                                className="nopadd text-center form-control" 
                                                disabled 
                                                value={key+1}
                                              />
                                            </Col>
                                            <Col xs={5}>
                                              <div className="nopadd text-center form-control">
                                                {formRow.teamName}
                                                <Badge
                                                  className={"ms-2 font-size-12 badge-soft-info"}
                                                >
                                                  {formRow.groupName}
                                                </Badge>
                                              </div>
                                            </Col>
                                            <Col xs="1">
                                              <Input 
                                                id={"team-score-"+formRow.idx}
                                                type="text"
                                                disabled
                                                className="nopadd text-center form-control" 
                                                value={teamRows.filter(x=>x["idx"]==formRow.idx)[0].score1}
                                              />
                                            </Col>
                                            {numberOfSets > 1 && 
                                            <Col xs="1">
                                              <Input 
                                                id={"team-score-"+formRow.idx}
                                                type="text" 
                                                disabled
                                                className="nopadd text-center form-control" 
                                                value={teamRows.filter(x=>x["idx"]==formRow.idx)[0].score2}
                                              />
                                            </Col>
                                            }
                                            {numberOfSets > 2 &&
                                            <Col xs="1">
                                              <Input 
                                                id={"team-score-"+formRow.idx}
                                                type="text"
                                                disabled
                                                className="nopadd text-center form-control" 
                                                value={teamRows.filter(x=>x["idx"]==formRow.idx)[0].score3}
                                              />
                                            </Col>
                                            }
                                            {numberOfSets > 3 &&
                                            <Col xs="1">
                                              <Input 
                                                id={"team-score-"+formRow.idx}
                                                type="text"
                                                disabled
                                                className="nopadd text-center form-control"
                                                value={teamRows.filter(x=>x["idx"]==formRow.idx)[0].score4}
                                              />
                                            </Col>
                                            }
                                            {numberOfSets > 4 &&
                                            <Col xs="1">
                                              <Input 
                                                id={"team-score-"+formRow.idx}
                                                type="text"
                                                disabled
                                                className="nopadd text-center form-control"
                                                value={teamRows.filter(x=>x["idx"]==formRow.idx)[0].score5}
                                              />
                                            </Col>
                                            }
                                            {/*<Col xs="1">
                                            <div className="nopadd text-center form-control">
                                              <input
                                                type="radio"
                                                id={"team-winner-"+formRow.idx}
                                                name="winner"
                                                value={teamRows.filter(x=>x["idx"]==formRow.idx)[0].winner}
                                                checked={teamRows.filter(x=>x["idx"]==formRow.idx)[0].winner}
                                                onClick={e => {
                                                  console.log('e.target.checked',e.target.checked)
                                                  console.log('e.target.value',e.target.value)
                                                  handleInputTextChange(formRow.idx, 'winner', 1)
                                                }}
                                                onChange={(e) => {
                                                }}
                                              />
                                              </div>
                                              </Col>
                                              */}
                                            {(!isFinalRound && !isLosersFinal) ? 
                                            <Col xs="1">
                                            <div className="nopadd text-center form-control">
                                              <input
                                                type="checkbox"
                                                className="nopadd text-center form-check-input"
                                                name={"team-selected-"+formRow.idx}
                                                id={"team-selected-"+formRow.idx}
                                                checked={teamRows.filter(x=>x["idx"]==formRow.idx)[0].selected}
                                                onClick={(e)=>{
                                                  handleInputTextChange(formRow.idx, 'selected', !teamRows.filter(x=>x["idx"]==formRow.idx)[0].selected)
                                                }}
                                                onChange={(e) => {
                                                }}
                                              />
                                            </div>
                                            </Col>
                                            :
                                            <Col xs="2" className="text-center">
                                              <Select
                                                value={getSelectedMedal(formRow.medal)}
                                                onChange={s => {
                                                  handleInputTextChange(formRow.idx, 'medal', s.value)
                                                  //handleMedalChange(formRow.idx, s)
                                                }}
                                                className="text-center"
                                                isLoading={inProgress}
                                                isDisabled={false}
                                                isMulti={false}
                                                options={medalDD}
                                                placeholder="Select..."
                                                classNamePrefix="select2-selection"
                                                menuPortalTarget={document.body}
                                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                              />
                                            </Col>
                                            }
                                          </Row>
                                        </Col>
                                      </Row>
                                    </td>

                                  </tr>
                                  ))}
                                
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </Row>
                    </Col>
                  </Row>
                  
                  
                  <Row className="mt-4 justify-content-center">
                    <Col className="text-center">
                      <Button
                        color="secondary"
                        className="w-lg me-2"
                        onClick={(e) => {
                          closePopUp()
                        }}
                        disabled={inProgress}
                        >
                           Close
                          {inProgress && <i className="bx bx-loader bx-spin font-size-16 align-middle ms-2 me-2"></i>}
                      </Button>
                      <Button
                        color="cust-1"
                        className="w-lg "
                        onClick={(e) => {
                          updateWinnerAndSelected()
                        }}
                        disabled={inProgress}
                        >
                          Save
                          {inProgress && <i className="bx bx-loader bx-spin font-size-16 align-middle ms-2 me-2"></i>}
                      </Button>
                    </Col>
                  </Row>

                </CardBody>
              </Card>
            </Col>
          </Row>
          
        </Container>
      </div>
    </React.Fragment>
  );
};

UpdateCompletion.propTypes = {
};

export default UpdateCompletion;
