import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap";
import {fetchDetails, insertDetails, updateDetails} from "helpers/backend_helper"
//redux
import { useSelector, useDispatch } from "react-redux";
import * as Yup from "yup";
import { useFormik } from "formik";
import CustomCreatableSelect from "components/Common/CustomCreatableSelect"
import { changePreloader } from "../../store/actions";
import { showSuccessAlert, showFailureAlert, findChangesV2 } from "helpers/function_helper"

const NewPlayer = props => {
  const dispatch = useDispatch();
  //const { match : { params } } = props

  const [inProgress, setInProgress] = useState(false);
  const [resetValue, setResetValue] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const getMyDetails=async(id)=>{
    let inputToSend = {
      "data": {
        "type": 'MY_PROFILE',
        "user": {
          "id": id,
        }
      }
    }
    const resp = await fetchDetails(inputToSend)
    if(resp && resp.status=='success'){
      let tempTempResp = resp.data
      //
      validation.setValues(resp.data)
      if(resp.data.squad){
        const squad = {
          value: resp.data.squad.key,
          label: resp.data.squad.value
        }
        validation.setFieldValue('squad',squad)
        tempTempResp = {
          ...tempTempResp,
          squad: squad
        }
        setCurrentSquad(squad)
      }
      if(resp.data.team){
        const team = {
          value: resp.data.team.key,
          label: resp.data.team.value
        }
        validation.setFieldValue('team',team)
        tempTempResp = {
          ...tempTempResp,
          team: team
        }
        setCurrentTeam(team)
      }
      if(resp.data.org){
        const org = {
          value: resp.data.org.key,
          label: resp.data.org.value
        }
        validation.setFieldValue('org',org)
        tempTempResp = {
          ...tempTempResp,
          org: org
        }
        setCurrentOrg(org)
      }
      setCurrentUserDetails(tempTempResp)
      validation.validateForm()
    }
  }

  const onSelect=(option)=>{
    //console.log('option:',option)
    validation.setFieldValue(option.objKey,option.selectedOption)
  }

  const validation = useFormik({
    enableReinitialize: false,
    initialValues: {
      playerNo: '',
      playerName: '',
      mobileNo: '',
      groupId: props.groupId,
    },
    validationSchema: Yup.object({
      playerNo: Yup.string().trim().required("Please enter the id."),
      playerName: Yup.string().trim().required("Please enter a name.").matches(/^[A-Za-z 0-9_]+$/, "Enter a valid name"),
      mobileNo: Yup.string().trim().required("Please enter a mobile no.").matches(/^[0-9-]+$/, "Please enter a valid mobile no."),
      groupId: Yup.string().trim().required("Please select the group."),
    }),
    onSubmit: (values) => {
      //console.log('values:',values)
      saveDetails(values)
    }
  });

  const saveDetails = async(values)=>{
    setInProgress(true)
    dispatch(changePreloader(true))

    if(isEdit){
      /*
      const changes = findChangesV2({...currentUserDetails}, values ,[])
      if(changes.length == 0){
        showFailureAlert('Nothing to Update...')
        setInProgress(false)
        dispatch(changePreloader(false))
        return
      }
      let inputToSend = {
        "data": {
          "type": 'UPDATE_USER',
          "user":{
            ...values,
            log: changes,
          }
        }
      }
      const resp = await updateDetails(inputToSend)
      if(resp && resp.status == 'success'){
        getMyDetails(params.id)
        showSuccessAlert('Updated Successfully!')
      }
      else{
        if(resp.error && resp.error.msg){
          showFailureAlert(resp.error.msg)
        }
        else{
          showFailureAlert('Unknown failure')
        }
      }
      */
    }
    else{
      let inputToSend = {
        "data": {
          "type": 'NEW_PLAYER',
          "player":{
            ...values
          }
        }
      }
      const resp = await insertDetails(inputToSend)
      //console.log('resp:',resp)
      if(resp && resp.status == 'success'){
        showSuccessAlert('Added Successfully!')
        validation.resetForm()
        props.onAdditionSuccess()
      }
    }

    setInProgress(false)
    dispatch(changePreloader(false))
  }

  const closePopUp=()=>{
    props.onClosePopUp()
  }

  return (
    <React.Fragment>
      <div className="">
        <Container fluid={true}>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row className="text-center mb-2">
                    <Col>
                      <span className="page-heading">Player Details</span>
                    </Col>
                  </Row>
                  <Row className="mt-3 align-items-center">
                    <Col xs={12} md={12}>
                      <Row>
                        <Col xs={12} md={2} className="text-sm-start text-md-end">
                          <Label
                            htmlFor="name"
                            className="col-form-label"
                          >
                            Group :
                          </Label>
                        </Col>
                        <Col xs={12} md={8} className="">
                          <Input
                            maxLength={100}
                            type="text"
                            name="groupName"
                            className="form-control"
                            id="groupName"
                            readOnly={true}
                            disabled={true}
                            value={props.groupName || ""}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="mt-3 align-items-center">
                    <Col xs={12} md={12}>
                      <Row>
                        <Col xs={12} md={2} className="text-sm-start text-md-end">
                          <Label
                            htmlFor="name"
                            className="col-form-label"
                          >
                            ID :
                          </Label>
                        </Col>
                        <Col xs={12} md={8} className="">
                          <Input
                            maxLength={20}
                            type="text"
                            name="playerNo"
                            className="form-control"
                            id="playerNo"
                            placeholder="ID"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.playerNo || ""}
                            invalid={
                              validation.touched.playerNo && validation.errors.playerNo ? true : false
                            }
                          />
                          {validation.touched.playerNo && validation.errors.playerNo ? (
                            <FormFeedback type="invalid">{validation.errors.playerNo}</FormFeedback>
                          ) : null}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="mt-3 align-items-center">
                    <Col xs={12} md={12}>
                      <Row>
                        <Col xs={12} md={2} className="text-sm-start text-md-end">
                          <Label
                            htmlFor="w2k"
                            className="col-form-label"
                          >
                            Name :
                          </Label>
                        </Col>
                        <Col xs={12} md={8} className="">
                          <Input
                            maxLength={100}
                            type="text"
                            name="playerName"
                            className="form-control"
                            id="playerName"
                            placeholder="Name"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.playerName || ""}
                            invalid={
                              validation.touched.playerName && validation.errors.playerName ? true : false
                            }
                          />
                          {validation.touched.playerName && validation.errors.playerName ? (
                            <FormFeedback type="invalid">{validation.errors.playerName}</FormFeedback>
                          ) : null}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="mt-3 align-items-center">
                    <Col xs={12} md={12}>
                      <Row>
                        <Col xs={12} md={2} className="text-sm-start text-md-end">
                          <Label
                            htmlFor="mobileNo"
                            className="col-form-label"
                          >
                            Mobile No :
                          </Label>
                        </Col>
                        <Col xs={12} md={8} className="">
                          <Input
                            maxLength={16}
                            type="tel"
                            name="mobileNo"
                            className="form-control"
                            id="mobileNo"
                            placeholder="Mobile No."
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.mobileNo || ""}
                            invalid={
                              validation.touched.mobileNo && validation.errors.mobileNo ? true : false
                            }
                          />
                          {validation.touched.mobileNo && validation.errors.mobileNo ? (
                            <FormFeedback type="invalid">{validation.errors.mobileNo}</FormFeedback>
                          ) : null}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  
                  <Row className="mt-4 justify-content-center">
                    <Col className="text-center">
                      <Button
                        color="secondary"
                        className="w-lg me-2"
                        onClick={(e) => {
                          closePopUp()
                        }}
                        disabled={inProgress}
                        >
                           Close
                          {inProgress && <i className="bx bx-loader bx-spin font-size-16 align-middle ms-2 me-2"></i>}
                      </Button>
                      <Button
                        color="cust-1"
                        className="w-lg "
                        onClick={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                        disabled={inProgress}
                        >
                          Save
                          {inProgress && <i className="bx bx-loader bx-spin font-size-16 align-middle ms-2 me-2"></i>}
                      </Button>
                    </Col>
                  </Row>

                </CardBody>
              </Card>
            </Col>
          </Row>
          
        </Container>
      </div>
    </React.Fragment>
  );
};

NewPlayer.propTypes = {
};

export default NewPlayer;
