import { takeEvery, fork, put, all, call } from "redux-saga/effects"

import { GET_MY_EVENTS } from "./actionTypes"
import { getMyEventsSuccessful } from "./actions"

import { fetchDetails } from "helpers/backend_helper"

function* getNotesTitles({ payload:  data  }) {
  try {
    
    const response = yield call(fetchDetails, data)
    yield put(getMyEventsSuccessful(response))
    
  } catch (error) {
    //yield put(registerUserFailed(error))
  }
}

export function* watchGetMyEvents() {
  yield takeEvery(GET_MY_EVENTS, getNotesTitles)
}

function* OlympicsSaga() {
  yield all([fork(watchGetMyEvents)])
}

export default OlympicsSaga
