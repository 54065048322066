import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import AuthCode from "react-auth-code-input"
import { 
  Row, 
  Col, 
  CardBody, 
  Card, 
  Alert, 
  Container,
  Form,
  FormGroup, 
  Button,
} from "reactstrap"
import { Auth } from "aws-amplify";
import { withRouter, Link } from "react-router-dom"
import { showSuccessAlert } from "helpers/function_helper";

// import images
import profileImg from "../../assets/images/profile-img.png"

import logoImg from "assets/images/logo_black-c.png"

const VerifyAccount = props => {
  
  const [verifyAccountError, setVerifyAccountError] = useState()
  const [inProgress, setInProgress] = useState(false)
  const [resending, setResending] = useState(false)
  const [resentSuccessFull, setResentSuccessFull] = useState(false)
  
  const [verifyAccountSuccess, setVerifyAccountSuccess] = useState(false)


  // handleValidSubmit
  const handleValidSubmit = confirmCode => {
    setResentSuccessFull(false)
    if(confirmCode && confirmCode.length == 6){
      setInProgress(true)
      setVerifyAccountError()
      //console.log('props.username>:'+(props.username))
      //console.log('confirmCode>:'+(confirmCode))
      Auth.confirmSignUp(props.username,confirmCode)
      .then((user) => {
        setVerifyAccountSuccess(true)
        //console.log(user);
        setInProgress(false)
        showSuccessAlert()
        props.history.push("/login")
      })
      .catch((err) => {
        if(err.code && err.code=='ExpiredCodeException'){
          setVerifyAccountError('Code is expired.')
        }
        else if(err.code && err.code=='CodeMismatchException'){
          setVerifyAccountError('Invalid verification code provided, please try again.')
        }
        console.log('Error:',JSON.stringify(err));
        setInProgress(false)
      });
    }
  }

  async function resendConfirmationCode() {
    try {
        setVerifyAccountError()
        setInProgress(true)
        setResentSuccessFull(false)
        setResending(true)
        const resp = await Auth.resendSignUp(props.username);
        setResentSuccessFull(true)
        setResending(false)
        setInProgress(false)
    } catch (err) {
        setInProgress(false)
        setResending(false)
    }
}

function onConfirmToLogin(){
  props.history.push("/login")
}

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col className="col-7">
                      <div className="text-warning p-4">
                        <h5 className="text-warning">Verify Your Account</h5>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profileImg} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logoImg}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>

                  <div className="">
                    <div className="text-center">
                      <div className="avatar-md mx-auto">
                        <div className="avatar-title rounded-circle bg-light">
                        {inProgress ? 
                          <i className="bx bx-loader bx-spin h1 mb-0 text-primary"></i> :
                          <i className="bx bxs-envelope h1 mb-0 text-primary"></i>}
                        </div>
                      </div>
                      <div className="p-2 mt-4">
                        <h4>Verify your email</h4>
                        <p className="mb-5">
                          Please enter the 6 digit code sent to{" "}
                          <span className="font-weight-semibold">
                            {props.maskedEmail}
                          </span>
                        </p>

                        <Form>
                          <Row>
                            <Col xs={12}>
                              <FormGroup className="verification mb-3">
                                <label
                                  htmlFor="digit1-input"
                                  className="sr-only"
                                >
                                  Verify Email
                                </label>
                                {!resending && <AuthCode
                                  characters={6}
                                  className="form-control form-control-lg text-center"
                                  allowedCharacters="numeric"
                                  inputStyle={{
                                    width: "76px",
                                    height: "42px",
                                    padding: "8px",
                                    borderRadius: "8px",
                                    fontSize: "16px",
                                    textAlign: "center",
                                    marginRight: "15px",
                                    border: "1px solid #ced4da",
                                    textTransform: "uppercase",
                                  }}
                                  onChange={(e) => (
                                    handleValidSubmit(e)
                                  )}
                                />}
                              </FormGroup>
                              {verifyAccountError && verifyAccountError ? (
                                <Alert color="danger"> 
                                  <span className="alert-icon"><i className="mdi mdi-thumb-down me-1 font-size-18 align-middle"></i></span>
                                  <span className="alert-text align-middle">{verifyAccountError}</span>
                                  <Link 
                                    to="/#" 
                                    className="ms-2 align-middle" 
                                    onClick={(e) => {
                                      e.preventDefault()
                                      resendConfirmationCode()}
                                    } 
                                    variant="outline-success">
                                    Resent?
                                  </Link>
                                </Alert>
                              ) : null}
                              {resentSuccessFull && 
                                <Alert color="warning"> 
                                  <span className="alert-icon"><i className="mdi mdi-thumb-up me-1 font-size-18 align-middle"></i></span>
                                  <span className="alert-text align-middle">Code re-sent, please re-enter.</span>
                                </Alert>
                                }
                            </Col>
                          </Row>
                        </Form>
                      </div>
                    </div>
                  </div>

                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Already have an account ?{" "}
                  <Link to="/login" className="font-weight-medium text-primary">
                    {" "}
                    Login
                  </Link>{" "}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

    </React.Fragment>
  )
}

export default withRouter(VerifyAccount)

VerifyAccount.propTypes = {
  history: PropTypes.object,
}
