import {
  GET_MY_EVENTS,
  GET_MY_EVENTS_SUCCESS
} from "./actionTypes"

export const getMyEventsSuccessful = events => {
  return {
    type: GET_MY_EVENTS_SUCCESS,
    payload: events,
  }
}

export const getMyEvents = data => {
  return {
    type: GET_MY_EVENTS,
    payload: data,
  }
}
