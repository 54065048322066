import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";

// //Import Scrollbar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { fetchDetails } from "helpers/backend_helper"
import { getLoggedInUserDetails } from "helpers/function_helper"
import { useSelector, useDispatch } from "react-redux";
import { getMyEvents } from "../../store/actions";
//i18n
import { withTranslation } from "react-i18next";

const SidebarContent = props => {
  const dispatch = useDispatch();
  const ref = useRef();

  const { events } = useSelector(state => ({
    events: state.Olympics.events
  }));

  const [inProgress, setInProgress] = useState(false);
  const [localEvents, setLocalEvents] = useState([]); 

  useEffect(() => {
    console.log('setting local events')
    if(events && events.data){
      setLocalEvents(events.data)
    }
  },[events]);

  /*
  const [events, setEvents] = useState([{
    "id": 123,
    "eventId": "a123-v324-sdf4-435d",
    "eventName": "TCS Olympics 2022",
    "eventCode": "tcs_olympics_2022"
  }])
  */

  const getMyEventsLocal=async()=>{
    const user = await getLoggedInUserDetails()
    setInProgress(true)
    let inputToSend = {
      "data":{
        "type": 'MY_EVENTS',
        "user":{
          "id": user.username,
        }
      }
    }
    //const resp = await fetchDetails(inputToSend);
    console.log('calling events..')
    dispatch(getMyEvents(inputToSend))
    //console.log(resp)
    setInProgress(false)
  }

  useEffect(() => {
    getMyEventsLocal()
  },[]);

  useEffect(() => {
    const pathName = props.location.pathname;

    const initMenu = () => {
      new MetisMenu("#side-menu");
      let matchingMenuItem = null;
      const ul = document.getElementById("side-menu");
      const items = ul.getElementsByTagName("a");
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i];
          break;
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    initMenu();
  }, [props.location.pathname]);

  useEffect(() => {
    ref.current.recalculate();
  });

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu" aria-expanded="true">
            <li>
              <Link to="/dashboard" >
                <i className="bx bx-store"></i>
                <span>{props.t("Dashboard")}</span>
              </Link>
            </li>
            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-list-ul"></i>
                <span>{props.t("Events")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="true">
                <li>
                  <Link to="/newevent" className="text-primary">
                    <i className="bx bx-plus text-primary" />{props.t("New Event")}
                  </Link>
                </li>
                {localEvents.map((each, i)=>(
                  <li key={i}>
                    <Link to={"/event/"+each.eventCode}>{each.eventName}</Link>
                  </li>
                ))}
                
              </ul>
            </li>

          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarContent));
