import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { differenceBy } from "lodash";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
  Modal
} from "reactstrap";
import {fetchDetails, insertDetails, updateDetails} from "helpers/backend_helper"
//redux
import { useSelector, useDispatch } from "react-redux";
import Slide from "react-reveal/Slide";
import Select from "react-select";
import CustomCreatableSelect from "components/Common/CustomCreatableSelect"
import { changePreloader } from "../../store/actions";
import { showSuccessAlert, showFailureAlert, createGroupDropDown, showCloseableToast } from "helpers/function_helper"
import NewPlayer from "./NewPlayer"

const AddNewTeam = props => {
  const dispatch = useDispatch();

  const [retriggerCounter, setRetriggerCounter] = useState(2)
  const [addPlayerModal, setAddPlayerModal] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  const [resetForm, setResetForm] = useState(false)
  const [resetPlayers, setResetPlayers] = useState(false)
  const [isEdit, setIsEdit] = useState(false);

  const [teamDetails, setTeamDetails] = useState({
    teamName: '',
    teamGroup: '',
    teamGroupName: ''
  });
  const [teamMembers, setTeamMembers] = useState([])

  const [validation, setValidation] = useState({
    teamName: null,
    teamGroup: null
  })

  const initializeValues =()=>{
    setTeamDetails({
      teamName: '',
      teamGroup: '',
      teamGroupName: ''
    })
    setTeamMembers([])
    setResetForm(true)
    setResetPlayers(true)
    setTimeout(() => {
      setResetForm(false)
      setResetPlayers(false)
    }, 100);
  }

  useEffect(()=>{
    console.log('props.teamDetailsToEdit:',props.teamDetailsToEdit)
    if(props.teamDetailsToEdit){
      setIsEdit(true)
      setTeamDetails({
        id:props.teamDetailsToEdit.id,
        teamName: props.teamDetailsToEdit.team_name,
        teamGroup: props.teamDetailsToEdit.group_name,
        teamGroupName: props.teamDetailsToEdit.group_name
      })
    }
  },[props.teamDetailsToEdit])
  
  const cancelAddTeam=()=>{
    props.onConfirmAddTeam()
  }

  const mandatoryFields = ['teamName','teamGroup']
  const onChangeValidation = (fieldName, value) =>{
    if(mandatoryFields.includes(fieldName)){
      const modifiedV = { ...validation }
      if(value !== ""){
        modifiedV[fieldName] = true
      }
      else{
        modifiedV[fieldName] = false
      }
      setValidation(modifiedV)
    }
  }

  const onSelect=(option)=>{
    console.log('option:',option)
    if(option && option.selectedOption && option.selectedOption.value){
      setTeamDetails({
        ...teamDetails,
        teamGroup: option.selectedOption.value,
        teamGroupName: option.selectedOption.label
      })
      onChangeValidation('teamGroup',option.selectedOption.value)
      setResetPlayers(true)
      setTimeout(() => {
        setResetPlayers(false)
      }, 100);
    }
    //validation.setFieldValue(option.objKey,option.selectedOption)
  }

  const onSelectPlayer=(option)=>{
    console.log('onSelectPlayer:',option)
    if(option && option.selectedOption){
      setTeamMembers(option.selectedOption)

      const modifiedV = { ...validation }
      if(option.selectedOption.length > 0){
        modifiedV['teamMembers'] = true
      }
      else{
        modifiedV['teamMembers'] = false
      }
      setValidation(modifiedV)

      //onChangeValidation('teamMembers',option.selectedOption)
    }
    //validation.setFieldValue(option.objKey,option.selectedOption)
  }


  function validateFormSubmit(){
    let formValid = true
    const modifiedView = { ...validation }
    mandatoryFields.forEach(eachItem => {
      //console.log('teamDetails[eachItem]:',teamDetails[eachItem],':eachItem:',eachItem)
      if(teamDetails[eachItem] && String(teamDetails[eachItem]).trim() != ''){
        modifiedView[eachItem] = true
      }
      else{
        modifiedView[eachItem] = false
        formValid = false
      }
    })
    if(!isEdit && teamMembers.length == 0){
      modifiedView['teamMembers'] = false
      formValid = false
    }
    
    setValidation(modifiedView)
    return formValid
  }

  /*
  export interface ITeam {
    eventCode?: string;
    groupName?: string;
    teamName?: string;
    groupId?:string;
    gameId?:string;
    players?: ITeamPlayer[];
  }
  export interface ITeamPlayer {
    id?: string;
    playerNo?: string;
    playerName?: string;
    groupId?:string;
    gameId?:string;
  }
*/
//console.log('selectedEventOption:',props.selectedEventOption)
  const addNewTeamToGame=async()=>{
    const isValid = validateFormSubmit()
    if(!isValid){
      return
    }
    setInProgress(true)
    dispatch(changePreloader(true))
    let inputToSend = {
      "data": {
        "type": 'NEW_TEAM',
        "team":{
          teamName: teamDetails.teamName,
          groupId : teamDetails.teamGroup,
          gameId: props.selectedEventOption.id,
          players: teamMembers
        }
      }
    }
    const resp = await insertDetails(inputToSend)
    //console.log('resp:',resp)
    if(resp && resp.status == 'success'){
      initializeValues()
      showCloseableToast('Added Successfully', 'top-right','success')
      props.onConfirmAddTeam(resp.data.id)
    }
    else{
      if(resp.error && resp.error.msg){
        showFailureAlert(resp.error.msg)
      }
      else{
        showFailureAlert('Unknown failure')
      }
    }

    setInProgress(false)
    dispatch(changePreloader(false))
  }

  const updateTeamDetails=async()=>{
    const isValid = validateFormSubmit()
    console.log('isValid::',isValid)
    if(!isValid){
      return
    }
    setInProgress(true)
    dispatch(changePreloader(true))
    let inputToSend = {
      "data": {
        "type": 'UPDATE_TEAM',
        "team":{
          id:teamDetails.id,
          teamName: teamDetails.teamName,
          //groupId : teamDetails.teamGroup,
          //gameId: props.selectedEventOption.id,
          //players: teamMembers
        }
      }
    }
    const resp = await updateDetails(inputToSend)
    //console.log('resp:',resp)
    if(resp && resp.status == 'success'){
      initializeValues()
      showCloseableToast('Updated Successfully', 'top-right','success')
      props.onConfirmEditTeam(resp.data.id)
    }
    else{
      if(resp.error && resp.error.msg){
        showFailureAlert(resp.error.msg)
      }
      else{
        showFailureAlert('Unknown failure')
      }
    }

    setInProgress(false)
    dispatch(changePreloader(false))
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  function tog_addPlayerModal() {
    setAddPlayerModal(!addPlayerModal);
    removeBodyCss();
  }

  const addNewPlayer=()=>{
    if(teamDetails.teamGroup && teamDetails.teamGroup != ''){
      tog_addPlayerModal()
    }
    else{
      onChangeValidation('teamGroup','')
    }
  }

  const onAdditionSuccess=()=>{
    setRetriggerCounter(retriggerCounter+1)
  }

  return (
    <React.Fragment>
      <Slide duration={300} right opposite>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <Row>
                <Col xs="12">
                  <div className=" d-flex align-items-center justify-content-between">
                    <span className="page-heading">{isEdit ? 'Edit': 'Add Team'}</span>
                  </div>
                </Col>
              </Row>
              <Row className="mt-3 align-items-center">
                <Col xs={12} md={12}>
                  <Row>
                    <Col xs={12} md={2} className="text-sm-start text-md-end">
                      <Label
                        htmlFor="name"
                        className="col-form-label"
                      >
                        Team Name:
                      </Label>
                    </Col>
                    <Col xs={12} md={4} className="">
                      <Input
                        maxLength={150}
                        type="text"
                        name="teamName"
                        className="form-control"
                        id="teamName"
                        placeholder="Team Name"
                        onChange={e=>{
                          setTeamDetails({
                            ...teamDetails,
                            teamName: e.target.value
                          })
                          onChangeValidation('teamName',e.target.value)
                        }}
                        value={teamDetails.teamName || ""}
                      />
                      {validation['teamName'] == false &&
                        <div className="custom-error">{"Please enter the team name."}</div>
                      }
                    </Col>
                    {!isEdit && <>
                    <Col xs={12} md={1} className="text-sm-start text-md-end">
                      <Label
                        htmlFor="group"
                        className="col-form-label"
                      >
                        Group:
                      </Label>
                    </Col>
                    <Col xs={12} md={4} className="">
                      <CustomCreatableSelect sType="GROUPS" eventCode={props.eventCode} sKey={props.eventCode} onSelect={onSelect} creatable={true} defaultValue={null} resetValue={resetForm}/>
                      {validation['teamGroup'] == false &&
                        <div className="custom-error">{"Please select the team group."}</div>
                      }
                    </Col>
                    </>}

                  </Row>
                  {!isEdit && 
                  <Row className="mt-3">
                    <Col xs={12} md={2} className="text-sm-start text-md-end">
                      <Label
                        htmlFor="name"
                        className="col-form-label"
                      >
                        Team Players:
                      </Label>
                    </Col>
                    <Col xs={12} md={8} className="">
                      <CustomCreatableSelect retriggerCounter={retriggerCounter} eventCode={props.eventCode} sType="PLAYERS" sKey={teamDetails.teamGroup} onSelect={onSelectPlayer} creatable={false} isMultiOpt={true} defaultValue={null} resetValue={resetPlayers}/>
                      {validation['teamMembers'] == false &&
                        <div className="custom-error">{"Please select the player(s)."}</div>
                      }
                    </Col>
                    <Col xs={12} md={1} className="">
                      <Button
                        color="primary"
                        className="me-2"
                        onClick={(e) => {
                          addNewPlayer()
                        }}
                        disabled={inProgress}
                        >
                          + Player
                      </Button>
                    </Col>
                  </Row>
                  }

                  
                </Col>
              </Row>
              
              <Row className="mt-4 justify-content-center">
                <Col className="text-center">
                  <Button
                    color="secondary"
                    className="w-lg me-2"
                    onClick={(e) => {
                      cancelAddTeam()
                    }}
                    disabled={inProgress}
                    >
                      Close
                  </Button>
                  {!isEdit && <Button
                    color="cust-1"
                    className="w-lg "
                    onClick={(e) => {
                      addNewTeamToGame()
                    }}
                    disabled={inProgress}
                    >
                      ADD
                      {inProgress && <i className="bx bx-loader bx-spin font-size-16 align-middle ms-2 me-2"></i>}
                  </Button>
                  }
                  {isEdit && <Button
                    color="cust-1"
                    className="w-lg "
                    onClick={(e) => {
                      updateTeamDetails()
                    }}
                    disabled={inProgress}
                    >
                      Update
                      {inProgress && <i className="bx bx-loader bx-spin font-size-16 align-middle ms-2 me-2"></i>}
                  </Button>
                  }
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      </Slide>

      <Modal
        size="xl"
        isOpen={addPlayerModal}
        toggle={() => {
          tog_addPlayerModal();
        }}
      >
        <div className="modal-header">
          <h5
            className="modal-title mt-0"
            id="myExtraLargeModalLabel"
          >
            Details
          </h5>
          <button
            onClick={() => {
              tog_addPlayerModal();
            }}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <NewPlayer groupId={teamDetails.teamGroup} groupName={teamDetails.teamGroupName} eventCode={props.eventCode} onClosePopUp={()=>tog_addPlayerModal()} onAdditionSuccess={onAdditionSuccess}/>
        </div>
      </Modal>

    </React.Fragment>
  );
};

AddNewTeam.propTypes = {
};

export default AddNewTeam;
